import React, { useState, useEffect } from "react";
import GradeFilters from "../FlashCards/gradeFilters";
import classes from "../../styles/smartLearn.module.css";
import GradesHeader from "./gradesHeader";
import {getEnvUrl} from "../../utils/Api"
import SmartLearnCarouselView from "./smartLearnCarouselView";
import { getQuizFc } from "../../services/contentService"; 
import GradeSkeleton from "../Skeleton/gradeSkeletion"
import BodySkeleton from "../Skeleton/bodyskeleton"
import { useSelector, useDispatch } from 'react-redux';
import {fetchGrades} from "../../redux/actions/homePageActions"

const SmartLearn = () => {
  const id = "grades";
  const id1 = "flash-cards";
  const dispatch      = useDispatch(); 
  const [activeTab, setActiveTab] = useState('');  
  const [scrolled, setScrolled]   = useState(false);  
  const [grades, setGrades]       = useState([]);  
  const [fcQuizData, setFcQuizData] = useState([]);  
  const [isGradeLoading,setisGradeLoading]  = useState(true);
  const [isLoading,setIsLoading]  = useState(true);
  const [dataLength,setDataLength]  = useState(true);
  const app_grades = useSelector(state => state.Home.grades); 
  const changeGrade=(grade_id)=>{
    setActiveTab(grade_id)
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100 ) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  useEffect(()=>{
    if(activeTab!=''){
      getQuizFc(activeTab).then((res)=>{
        if(res.status && res.data){
          setFcQuizData(res.data)
          setDataLength(res.data.length===1)
          setTimeout(() => {
            setIsLoading(false)
          }, 500);
        
        }
      }) 
    } 
  },[activeTab])

  useEffect(() => {
    if(app_grades?.status==undefined){ 
      dispatch(fetchGrades());
    } 
  }, [dispatch]);
  useEffect(() => { 
      if (app_grades.status) {
        const data = app_grades.data.map((obj) => ({
          id  : obj.grade_id,
          img : `${getEnvUrl()}/content/getThumbnail?moduleName=grades&id=${obj.grade_id}`,
        }));
        setGrades(data);
        setTimeout(() => {
          setisGradeLoading(false)
        }, 500);
        setActiveTab(data[0].id)
      } 
  }, [app_grades]);

  return (
    <>
      <div
        id={id}
        className={!dataLength && scrolled ? classes.gradeNavHead : classes.gridFilterHead}
      >
        {!dataLength && scrolled ?<>
          {isGradeLoading && <GradeSkeleton/>}
          {!isGradeLoading && <GradesHeader gradesData={grades} activeTab={activeTab} changeGrade={changeGrade}/>}
        </>
          : <>
           {isGradeLoading && <GradeSkeleton/>}
           {!isGradeLoading &&  <GradeFilters activeTab={activeTab} gradesData={grades} changeGrade={changeGrade}/>}
          </>
         }
      </div>
      <div className={classes.flashCardHead} id={id1}>
      {isLoading && <BodySkeleton />} 
      {!isLoading && <>
        {fcQuizData.length!==0?(fcQuizData.map((carousel, index) => (
          <>
            {/* <div className={classes.topLine}></div> */}
            <SmartLearnCarouselView
              title={carousel.title} 
              data={carousel.data}
              id={carousel.id}
              colorIndex={index}
            />
            {/* <div className={classes.bottomLine}></div> */}
          </>
        ))):(<div className={classes.noDataContainer}>
            <h3>No data found</h3>
          </div>)}</>}
        
      </div>
    </>
  );
};

export default SmartLearn;
