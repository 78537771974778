import React, { useEffect, useState, useCallback } from "react";
import { Container, Image } from "react-bootstrap";
import classes from "../../styles/search.module.css";
import Styles from "../../styles/trendingVideo.module.css";

import TrendingVideo from "../HomePage/trendingVideo";
import { isUserLogin } from "../../utils/Api";
import { searchContents } from "../../services/contentService";
import debounce from "lodash/debounce";

const Search = () => {
  const { isAuthenticated } = isUserLogin();
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);

  const handleSearch = useCallback(
    debounce(async (value) => {
      try {
        const response = await searchContents(value);
        if (response.status && response.data) {
          setSearchData(response.data);
        } else {
          setSearchData([]);
        }
      } catch (error) {
        console.error("Search API call failed:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, handleSearch]);

  const onSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClose = () => {
    setSearchValue("");
    setSearchData([]);
  };

  return (
    <>
    <div style={{minHeight:'700px'}}>
    <div fixed="top" className={`mb-3 container ${classes.MainNav}`}>
        <Container className={classes.HeaderMain}>
          <div className={classes.searchHead}>
            <Image src="/svgs/searchIcon.svg" width={30} height={30} />
            <input
              placeholder="Flash Cards, Quiz and more"
              className={classes.searchInput}
              value={searchValue}
              onChange={onSearchInputChange}
            />
            {searchValue && (
              <Image
                src="/svgs/close.svg"
                alt="CloseIcon"
                className={classes.xbutton}
                width={24}
                onClick={handleClose}
              />
            )}
          </div>
        </Container>
      </div>
      {searchData.length > 0 && searchData[0].data.length === 0 ? (
        <>
          <div className={classes.noDatasContainer}>
            <h5 >{searchData[0].title}</h5>
          </div>
          <div className={classes.noDataContainer}>
            <h3>No data found</h3>
          </div>
          </>
      ) : (
        searchData.map((datasearch, index) => (
          <TrendingVideo
            key={datasearch.id ? datasearch.id : `${datasearch.title}-${index}`}
            title={datasearch.title}
            data={datasearch.data}
          />
        ))
      )}
    </div>
      
    </>
  );
};

export default Search;
