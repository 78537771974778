import { getEnvUrl,getToken,axiosInstance } from "../utils/Api";
import { v4 as uuidv4 } from 'uuid';
export async function UserLogin(email, password) {
  try { 
      const response = await axiosInstance.post("user/userLogin",{email:email,password:password,deviceID:uuidv4()},{
        
    });
    return response.data;    
  } catch (error) {
    console.error('Error occurred during login:', error);
    return false;
  }
}

export const logout = async () =>{ 
  try {
      const response = await axiosInstance.post("user/userLogout",[],{
          headers: { 
              Authorization: `Bearer ${getToken()}`,
          }
      });
      return response.data;
    } catch (error) {
      throw error;
    } 
} 

export const profilePicUpload = async (formData) =>{ 
  try {
      const response = await axiosInstance.put("user/updateProfilePic",formData,{
          headers: { 
            'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${getToken()}`,
          }
      });
      return response.data;
    } catch (error) {
      throw error;
    } 
} 

export const getUserDetails = async (formData) =>{ 
  try {
      const response = await axiosInstance.get("user/getUser",{
          headers: {  
              Authorization: `Bearer ${getToken()}`,
          }
      });
      return response.data;
    } catch (error) {
      throw error;
    } 
} 
export const updateUserProfile = async (data) =>{ 
  try {
      const response = await axiosInstance.put("user/updateUser",data,{
          headers: {  
              Authorization: `Bearer ${getToken()}`,
          }
      });
      return response.data;
    } catch (error) {
      throw error;
    } 
} 
export const updatePassword = async (data) =>{ 
  try {
      const response = await axiosInstance.put("user/updateUserPassword",data,{
          headers: {  
              Authorization: `Bearer ${getToken()}`,
          }
      });
      return response.data;
    } catch (error) {
      throw error;
    } 
} 

export const getLoginDevices = async (formData) =>{ 
  try {
      const response = await axiosInstance.get("user/getLoginDevices",{
          headers: { 
            'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${getToken()}`,
          }
      });
      return response.data;
    } catch (error) {
      throw error;
    } 
}

export const signOutDevice = async (deviceid) => { 
  try {
    const response = await axiosInstance.delete(`user/logoutOfDevices`, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        deviceid: deviceid
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  } 
}
export async function resetPassword(email) {
    try {
        const response = await axiosInstance.post('/user/forgotPassword', { email });

        if (response.status) {
            return response.data;
        } else {
            console.log('Error: No response status');
        }
    } catch (error) { 
        throw error;
    }
}

export async function watchTime(obj) {
  try {
      const response = await axiosInstance.post('/user/watchTime', obj,
       { headers: {  
          Authorization: `Bearer ${getToken()}`,
      }}
      ); 
      return response.data;
  } catch (error) { 
      throw error;
  }
}