import { getEnvUrl,getToken,axiosInstance } from "../utils/Api";

export const getFcDetails = async (fc_id) =>{ 
try {
    const response = await axiosInstance.get(`fc/getFlashCardDetails?fc_id=${fc_id}`,{
        headers: { 
            Authorization: `Bearer ${getToken()}`,
        }
    });
    return response.data;
    } catch (error) {
    throw error;
    } 
}

export const getFcSlideDetails = async (fc_id) =>{ 
    try {
        const response = await axiosInstance.get(`fc/getFcSlideDetails?fcid=${fc_id}`,{
            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
        } catch (error) {
        throw error;
        } 
    }   
    
