import React, { useState, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./components/footer";
import classes from "./styles/HomePage/homepage.module.css";
import Header from "./components/header";
import Login from "./pages/LoginPage/login";
import { isUserLogin } from "./utils/Api"; 
const BodyView = () => {
  const isAuthenticated = isUserLogin();
  const location = useLocation();
  const scrollToTopRef = useRef(null);
  const currentPath = location.pathname; 
  useEffect(() => {
    window.scrollTo(0, 0);
    scrollToTop();
  }, [currentPath]);

  const scrollToTop = () => {
    scrollToTopRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className={ classes.main} > 
      <div ref={scrollToTopRef} />
      <Outlet /> 
    </div> 
  );
};

export default BodyView;
