import React,{useEffect,useRef} from "react";
import Lottie from "react-lottie";
import { Col, Container, Image, Row } from "react-bootstrap";
import classes from "../../../styles/quizLevels.module.css";
import quizTrayAgainThumbDown from "../../../assets/json/thumbDown.json";
import quizTrayAgainBubbles from "../../../assets/json/bubbles.json";
import { useNavigate } from "react-router-dom";
const lottieOptions = (animationData) => ({
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
});

const QuizTryAgain = ({caCount,totalQues,quiz_id,color,tryAgain,precentage}) => {
  const navigate  = useNavigate(); 
  const audioRef  = useRef(null);
  useEffect(() => {  
    let soundkey='/sounds/HappyMagic.wav'
    if(color=='RED'){
      soundkey='/sounds/GameOver.wav'
    }
  audioRef.current = new Audio(soundkey);
  setTimeout(() => {
    audioRef.current.play().catch(error => {
      console.error('Error playing audio:', error);
  });
  }, 500);  
  return () => {
      if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
      }
  };
}, []); 
  return (
    <div className={classes.quizTrayAgainxBg}>
      <Container fluid className={classes.quizTrayAgainContainer}>
        <Row>
          <Col>
            <div className={classes.quizTrayAgainRowHead}>
              <div className={classes.quizTrayAgainRowHead2}>
                {/* <Image src="/svgs/arrow-back-level.svg" role="button" /> */}
                <Image
                  src="/svgs/Quiz/quiz-congrax-close-bg-icon.svg"
                  role="button"
                  onClick={()=>{navigate(`/quizDetailView/${quiz_id}`)}}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={color=='RED'?classes.quizTrayAgainRowHead4:classes.quizTrayAgainRowHead3}>
            <div className={classes.quizTrayAgainAnimation}>
              <Lottie
                options={lottieOptions(quizTrayAgainThumbDown)}
                width={56}
              />
            </div>
            <span className={classes.quizTrayAgainTitle}>
             
              {color!='RED'  && 'You are almost there!'} 
            </span>
            <div className={color=='RED'?classes.quizTrayAgainTitleOne1:classes.quizTrayAgainTitleOne}>
              Practice more
              <div className={classes.quizTrainAgainBubblesAnimation}>
                <Lottie
                  options={lottieOptions(quizTrayAgainBubbles)}
                  width={56}
                />
              </div>
            </div>
            <span className={classes.quizTrayAgainPara}>You had scored {precentage}%
            
            </span>
            <span className={classes.quizTrayAgainPara1}> 
            You have answered {caCount}/{totalQues} questions correctly in the quiz.
            </span>
            
           
            <button className={color=='RED'?classes.quizTrayAgainButton1:classes.quizTrayAgainButton} onClick={tryAgain}>
              <Image src="/svgs/Quiz/forward_circle.svg" />
              Try Again
            </button>
            <span className={classes.quizTrayAgainParaOne} onClick={tryAgain}>
           {/* {color!='RED'  && 'If you want to try again this level'}  */}
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default QuizTryAgain;
