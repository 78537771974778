import React, { useState, useEffect } from "react";
import { gradeFilters } from "../../utils/dummyData";
import CarouselView from "../HomePage/carousel";
import Carousel from "react-multi-carousel";
import classes from "../../styles/HomePage/homepage.module.css";
import { Container } from "react-bootstrap";
import CarouselCard from "../HomePage/carouselData";
import { FastAverageColor } from "fast-average-color";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const GradeFilters = ({ activeTab, gradesData, changeGrade }) => {
  const [hovered, setHovered] = useState(false);
  const [colors, setColors] = useState({});
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const gradeChange = (id) => {
    changeGrade(id);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  useEffect(() => {
    const fac = new FastAverageColor();
    const fetchColors = async () => {
      const newColors = {};
      for (let item of gradesData) {
        try {
          const color = await fac.getColorAsync(item.img);
          newColors[item.id] = color.rgba;
        } catch (error) {
          console.error("Error fetching color:", error);
          newColors[item.id] = "rgba(0, 0, 0, 0.5)"; // default color if error occurs
        }
      }
      setColors(newColors);
    };

    if (data?.length > 0) {
      fetchColors();
    }

    return () => {
      fac.destroy();
    };
  }, []);
  const data = gradesData?.length > 0 ? gradesData : gradeFilters;
  const carousels = [{ title: "Grades", data: data, type: "gradeFilter" }];
  return (
    <>
      {carousels?.map((carousel, index) => (
        <Container fluid className="pe-0 ps-5">
          <div className={classes.carouselHead}>
            <div className={classes.carouselSubHead}>
              <span className={classes.carouselTitle}>{carousel?.title}</span>
              {/* <span className={classes.carouselClick}>See all</span> */}
            </div>
            <Carousel
              responsive={responsive}
              containerClass="corousal-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              slidesToSlide={2}
            >
              {carousel.data?.map((item, i) => (
                <div
                  className={classes.card}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className={`${classes.imageContainer3} ${
                      activeTab == item.id && classes.imageContainer3Active
                    }`}
                  >
                    <img
                      onClick={() => {
                        gradeChange(item.id);
                      }}
                      src={
                        hovered &&
                        (carousel.title === "Subjects" ||
                          carousel.title === "Popular Subjects")
                          ? item.hoverImg
                          : item.img
                      }
                      alt="Img"
                      role="button"
                      className={`${classes.image} ${
                        carousel.title === "Grades" && classes.gradeImage
                      } ${
                        hovered &&
                        carousel.title === "Screen free learn" &&
                        classes.hoverAnimateOpacity
                      }`}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </Container>
      ))}
    </>
  );
};

export default GradeFilters;
