import React, { useState } from "react";
import { Container, Image } from "react-bootstrap";
import classes from "../../styles/Footer/contentList.module.css";
import { troubleshootData } from "../../utils/dummyData";

const Troubleshoot = () => {
  const [clickedId, setClickedId] = useState(null);

  const handleClick = (id) => {
    setClickedId(clickedId === id ? null : id);
  };
  return (
    <div className={`mb-3 ${classes.troubleShootMain}`}>
      <Container fluid>
        <div className={classes.troubleShootHeading}>Troubleshoot</div>
        {troubleshootData.map((item) => (
          <div key={item.id} className="mb-5">
            <div
              className={classes.troubleShootCardHead}
              onClick={() => handleClick(item.id)}
            >
              <div>
                <span>{item.id}. </span>
                <span>{item.question}</span>
              </div>
              <Image
                src={`/svgs/${
                  clickedId === item.id ? "circle-open.svg" : "circle-close.svg"
                }`}
              />
            </div>
            {clickedId === item.id && (
              <div className={classes.troubleShootAnserHead}>
                <span>{item.answer}</span>
              </div>
            )}
          </div>
        ))}
      </Container>
    </div>
  );
};

export default Troubleshoot;
