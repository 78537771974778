import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Image,
} from "react-bootstrap";
import { contentDummyData, gradesLatestImg } from "../../utils/dummyData";
import classes from "../../styles/Footer/contentList.module.css";
import "./ContentList.css";
import {getEnvUrl} from "../../utils/Api"
import {getContentIndex} from '../../services/contentService'
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {fetchGrades} from "../../redux/actions/homePageActions"
const ContentList = () => {
  const navigate  = useNavigate();
  const dispatch  = useDispatch();
  const { id }    = useParams(); 
  const app_grades = useSelector(state => state.Home.grades);
  const [key, setKey] = useState("");
  const [conentData, setConentData] = useState([]);
  const [grade_name, setGradeName]       = useState('-------');
  const [quizData, setQuizData] = useState([]);
  const [fcData, setFcData]     = useState([]); 
 
  const [gradesList, setGradesList] = useState([]); 
  useEffect(() => {
    if(app_grades?.status==undefined){ 
      dispatch(fetchGrades());
    } 
  }, [dispatch]);
  useEffect(() => { 
      if (app_grades.status) {
        setGradesList(app_grades.data)
        if (id==1) {
          setGradeName(app_grades.data[0].grade_name)
          setKey(app_grades.data[0].grade_id) 
        }else{
          setKey(id)
        }
      } 
  }, [app_grades]);
useEffect(()=>{
if(key!=''){ 
  for (const fruit of gradesList) {
   if(key==fruit.grade_id){
    setGradeName(fruit.grade_name)
    break;
   }
  }
}
},[key])

  

  useEffect(() => {
    if (key != "") {
      getContentIndex(key).then((res) => {
        setConentData(res.data.videoData);
        setQuizData(res.data.quizData);
        setFcData(res.data.fcData);
      });
    }
  }, [key]);

  return (
    <div className={`mb-3 ${classes.mainContentList}`}>
      <Container fluid>
        <h3 className={classes.contentHeading}>Content List</h3>
        <Tabs id="image-tabs" activeKey={key} onSelect={(k) => setKey(k)}>
          {gradesList.map((grade) => (
            <Tab
              key={grade.grade_id}
              eventKey={grade.grade_id}
              title={
                <div className="position-relative">
                  <Image
                    src={`${getEnvUrl()}/content/getThumbnail?moduleName=grades&id=${
                      grade.grade_id
                    }`}
                    alt={grade.grade_name}
                    className={classes.image}
                  />
                  {/* <span className={classes.gradesName}>{grade.grade_name}</span> */}
                </div>
              }
            >
              <div className={classes.contentCardHead}>
                <span className={classes.contentPara}>
                  Here's a list of the skills that students learn in  {grade_name},
                  organized into categories. To start learning, simply click on
                  any link.  Enjoy your learn!
                </span>
                <div className={`${classes.contentCardColHead} p-0`}>
                  <Row>
                    <Col>
                      <div>
                          <h4 className={classes.contentCardTitle}>Videos</h4>
                          {conentData.length==0 && <>
                         <b>Videos have not been added yet.</b> </>}
                        {conentData.map((obj) => {
                          return (
                            <> 
                              <h4 className={classes.contentCardTitle}>
                                {obj.concept_name}
                              </h4>
                              <ul
                                className={classes.subContentHead}
                                key={obj.concept_id}
                              >
                                {obj.content_data.map((dataobj) => {
                                  return (
                                    <>
                                      <li
                                        key={dataobj.id}
                                        onClick={() => {
                                          if (obj.ITEM_TYPE == "VIDEO") {
                                            navigate(
                                              `/videoDetailView/${dataobj.id}`
                                            );
                                          }
                                        }}
                                      >
                                        <span className={classes.contentText}>
                                          {dataobj.name}
                                        </span>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </>
                          );
                        })}
                          
                      </div>
                    </Col>

                    <Col> 
                      <div>
                        <h4 className={classes.contentCardTitle}>Quiz</h4>
                        {conentData.length==0 && <>
                          <b>Quiz have not been added yet.</b> </>}
                        <ul className={classes.subContentHead}>
                          {quizData.map((dataobj) => {
                            return (
                              <>
                                <li
                                  key={dataobj.id}
                                  onClick={() => {
                                    navigate(`/quizDetailView/${dataobj.id}`);
                                  }}
                                >
                                  <span className={classes.contentText}>
                                    {dataobj.title}
                                  </span>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <h4 className={classes.contentCardTitle}>
                          Flash cards
                        </h4>
                        {conentData.length==0 && <>
                          <b>Flash cards have not been added yet.</b> </>}
                        <ul className={classes.subContentHead}>
                          {fcData.map((dataobj) => {
                            return (
                              <>
                                <li
                                  key={dataobj.id}
                                  onClick={() => {
                                    navigate(
                                      `/flashCardDetailView/${dataobj.id}`
                                    );
                                  }}
                                >
                                  <span className={classes.contentText}>
                                    {dataobj.name}
                                  </span>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </div>
  );
};

export default ContentList;
