import React from "react";
import classes from "../../styles/Footer/contentList.module.css";
import { Col, Container, Image, Row } from "react-bootstrap";

const HelpAndSupport = () => {
  return (
    <div className={`mb-3 ${classes.helpAndSupportMain}`}>
      <Container fluid className="px-5">
        <div className={classes.helpAndSupportHead}>
          <h3 className={classes.helpAndSupportTitle}>
            How Can We Assist You?
          </h3>
          <hr className={classes.horizontalLine} />
        </div>
        <div className={classes.helpAndSupportHead2}>
          <Container className="px-4">
            <h3 className={classes.helpAndSupportSubHead}>
              Welcome to our Help and Support page! We're here to ensure you
              have the best experience possible. Below are various ways you
              can get in touch with us and get the assistance you need.​
            </h3>
            <div className={classes.helpAndSupportHead3}>
              <div className="d-flex gap-5 align-items-center justify-content-center">
                <Image src="/svgs/customer-care.svg" />
                <div className={classes.verticalLine}></div>
                <div>
                  <div className="mb-2">
                    <h3 className={classes.helpAndSupportSubHead1}>
                      Customer Care​
                    </h3>
                    <span className={classes.helpAndSupportPara}>
                      For any queries or immediate assistance,{" "}
                    </span>
                  </div>
                  <div>
                    <span className={classes.helpAndSupportPara2}>
                      please call our Customer Care number:​
                    </span>
                    <h3 className={classes.helpAndSupportSubHead2}>
                      +91 - XXXXX  XXXXX ​
                    </h3>
                  </div>
                </div>
              </div>
              <div className={classes.helpAndSupportHead4}>
                <span className={classes.helpAndSupportPara3}>
                  Our support team is available 24/7 to help you with any
                  questions or concerns.​​
                </span>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className={classes.helpAndSupportServiceHead}>
            <div className="mb-4">
              <h3 className={classes.bookServiceHead}>Book a Service​</h3>
              <span className={classes.bookServicePara}>
                Need to book a service? It's simple and quick!​
              </span>
            </div>
            <Row className="align-items-center">
              <Col sm={6}>
                <div className={classes.bookServiceBg}>
                  <div className="p-5">
                    <Image src="/svgs/online-booking.svg" className="mb-3" />
                    <h3 className={classes.bookServiceTitle}>Online Booking</h3>
                    <span className={classes.bookServicePara1}>
                      Visit our Service Booking Page to book a service online.
                      Visit our website 
                      <span className="text-decoration-underline">
                        www.mytalkingtree.com ,{" "}
                      </span>
                      fill out the form and our team will get back to you
                      shortly.
                    </span>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className={classes.bookServiceBg}>
                  <div className="p-5">
                    <Image src="/svgs/phone-booking.svg" className="mb-3" />
                    <h3 className={classes.bookServiceTitle}>Phone Booking</h3>
                    <span className={classes.bookServicePara1}>
                      Call our Customer Care number =91 - XXXXX  XXXXX and speak
                      with a representative to schedule your service.
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={classes.dottedLine}></div>
            <div className="mb-4">
              <h3 className={classes.bookServiceHead}>Track Your Service.</h3>
              <span className={classes.bookServicePara}>
                Want to check the status of your service? Follow these easy
                steps:
              </span>
            </div>
            <Row className="align-items-center">
              <Col sm={6}>
                <div className={classes.trackServiceBg}>
                  <div className="p-5">
                    <Image src="/svgs/online-tracking.svg" className="mb-3" />
                    <h3 className={classes.bookServiceTitle}>
                      Online Tracking
                    </h3>
                    <span className={classes.bookServicePara1}>
                      Visit our Service Tracking Page and enter your service ID
                      to see real-time updates.​
                    </span>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className={classes.trackServiceBg1}>
                  <div className="p-5">
                    <Image src="/svgs/phone-support.svg" className="mb-3" />
                    <h3 className={classes.bookServiceTitle}>Phone Support</h3>
                    <span className={classes.bookServicePara1}>
                      Call our Customer Care number =91 - XXXXX  XXXXX  and
                      provide your service ID to get the latest information from
                      our support team.​
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className={classes.helpAndSupportHeadLast}>
            <h3 className={classes.lastHeading}>
              If you need further assistance, feel free to reach out through our
              Contact Us Page or send us an email at (Your Support Email Here).
            </h3>
            <span className={classes.lastPara}>
              We're committed to providing you with exceptional support and
              ensuring your satisfaction.​
            </span>
            <div className="d-flex">
              <Image src="/svgs/ball.svg" />
              <h3 className={classes.lastHeading1}>
                Thank you for choosing us!
              </h3>
              <Image src="/svgs/ball.svg" />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default HelpAndSupport;
