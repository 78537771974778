import React, { useState,useEffect,useRef } from "react"; 
import Tempalte1 from "./templates/template1"
import {getFcSlideDetails} from "../../services/flashCardService"
import { useNavigate, useParams } from "react-router-dom";
const PlayFlashCard = () => {
  const [isLoading,setIsLoading] = useState(true)
  const audioRef = useRef(null);
  const { fc_id } = useParams();
  const navigate = useNavigate();
  const [fcSlides,setFcSlides]=useState([])
  const [currentSlide,setCurrentSlide]=useState([])
  const [totalSlides,setTotalSlides]=useState(1)
  const [currentSNumber,setcurrentSNumber]=useState(1)  
  useEffect(() => {
    if(!isLoading){
      // Create new Audio object and set the loop property to true
      audioRef.current = new Audio(`/sounds/ACTIONS_Background-1.mp3`);
      audioRef.current.loop = true;
      // Play the audio after a short delay
      setTimeout(() => {
        audioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
      }, 500);
      // Cleanup function to pause and nullify the audio reference
      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
        }
      };
    } 
  }, [isLoading]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = 'Are you sure you want to leave?';
      event.preventDefault();
      event.returnValue = confirmationMessage; // Most browsers will show the default confirmation dialog
      return confirmationMessage;
    }; 
    window.addEventListener('beforeunload', handleBeforeUnload); 
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); 
  const nextSlide=()=>{
    setCurrentSlide(fcSlides[(currentSNumber-1)+1])
    setcurrentSNumber(currentSNumber+1)
  }
  const prevSlide=()=>{
    setCurrentSlide(fcSlides[(currentSNumber-1)-1])
    setcurrentSNumber(currentSNumber-1)
  } 
  useEffect(()=>{
    getFcSlideDetails(fc_id).then((res)=>{
      if(res.status){
        setIsLoading(false)
        setFcSlides(res.data.slides)
        setCurrentSlide(res.data.slides[0])
        setTotalSlides(res.data.slides.length)
      }else{
        navigate('/')
      }
    })
  },[])
  return (
     <>
    {!isLoading && <Tempalte1 
      fc_id={fc_id}
      currentSNumber={currentSNumber}
      totalSlides={totalSlides}
      currentSlide={currentSlide}
      nextSlide={nextSlide}
      prevSlide={prevSlide}
     />}
     </>
  );
};

export default PlayFlashCard;
