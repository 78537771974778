import React from "react";
import { gradeFilters } from "../../utils/dummyData";
import { Image } from "react-bootstrap";
import classes from '../../styles/smartLearn.module.css'
import homeClasses from '../../styles/HomePage/homepage.module.css'
const GradesHeader = ({ gradesData,activeTab,changeGrade}) => { 
   const gradeChange = (id) => {
    changeGrade(id);
  }; 
  return (
    <div className={classes.navHeadImage}>
      {gradesData.map((item, index) => (
        <div key={index}   className={`${homeClasses.imageContainer31} ${activeTab==item.id && homeClasses.imageContainer31Active}`}>
          <Image src={item.img} className={classes.image} onClick={()=>{gradeChange(item.id)}} />
        </div>
      ))}
    </div>
  );
};

export default GradesHeader;
