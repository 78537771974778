import React, { useState, useEffect } from "react";
import GradeFilters from "../FlashCards/gradeFilters";
import classes from "../../styles/smartLearn.module.css";
import GradesHeader from "../SmartLearnPage/gradesHeader";
import { getEnvUrl } from "../../utils/Api"; 
import { useLocation } from "react-router-dom";
import { getQuizVideosBySubject } from "../../services/contentService";
import SmartLearnCarouselView from "../SmartLearnPage/smartLearnCarouselView";
import BodySkeleton from "../Skeleton/bodyskeleton"
import GradeSkeleton from "../Skeleton/gradeSkeletion"
import { useSelector, useDispatch } from 'react-redux';
import {fetchGrades} from "../../redux/actions/homePageActions"
const Subjects = () => {
  const id = "grades";
  const dispatch      = useDispatch(); 
  const location      = useLocation();
  const currentPath   = location.pathname;
  const pathSegments  = currentPath.split("/")[1];
  const [scrolled, setScrolled] = useState(false);
  const [grades, setGrades] = useState([]);
  const [grade_id, setGrade_id]   = useState("");
  const [contents, setContents]   = useState([]);
  const [activeTab, setActiveTab] = useState('');  
  const [isLoading,setIsLoading]  = useState(true);
  const [isGradeLoading,setisGradeLoading]  = useState(true);
  const app_grades = useSelector(state => state.Home.grades); 
  const changeGrade=(grade_id)=>{
    setActiveTab(grade_id)
  }
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if(app_grades?.status==undefined){ 
      dispatch(fetchGrades());
    } 
  }, [dispatch]);
  useEffect(() => { 
      if (app_grades.status) {
        const data = app_grades.data.map((obj) => ({
          id  : obj.grade_id,
          img : `${getEnvUrl()}/content/getThumbnail?moduleName=grades&id=${obj.grade_id}`,
        }));
        setGrades(data);
        setTimeout(() => {
          setisGradeLoading(false)
        }, 500);
        setActiveTab(data[0].id)
      } 
  }, [app_grades]);

  useEffect(() => {
    if(activeTab!=''){
      getQuizVideosBySubject(activeTab).then((res) => {
        if (res.status===true) {
           setContents(res.data); 
           setTimeout(()=>{
            setIsLoading(false)
           },500)
        }
      });
    } 
  }, [activeTab]);

  

  return (
    <>
{scrolled && <div style={{height:'300px'}}></div>} 
    <div className={scrolled ? classes.gradeNavHead : classes.gridFilterHead} id={id}>
        {scrolled ? (<>
          {isGradeLoading && <GradeSkeleton/>}
         {!isGradeLoading && <GradesHeader gradesData={grades} activeTab={activeTab} changeGrade={changeGrade}/>}
       </> 
        ) : ( <>
        {isGradeLoading && <GradeSkeleton/>}
        {!isGradeLoading && <GradeFilters gradesData={grades} activeTab={activeTab} changeGrade={changeGrade}/>}
        </> 
        )}
    </div> 

      <div className={classes.flashCardHead}>
      {isLoading && <BodySkeleton />} 
      {!isLoading && <>
        {contents.map((content, index) => (
          <React.Fragment key={index}>
            <SmartLearnCarouselView
              title={content.concept_name}
              data={content.data}
              routeName={pathSegments}
              id={content.concept_id}
              colorIndex={index} 
            />
          </React.Fragment>
        ))}</>}
       
      </div>
    </>
  );
};

export default Subjects;
