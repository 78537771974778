import React, { useState, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./components/footer";
import classes from "./styles/HomePage/homepage.module.css";
import Header from "./components/header";
import Login from "./pages/LoginPage/login";
import { isUserLogin } from "./utils/Api";
const MainView = () => {
  const isAuthenticated = isUserLogin();
  const location = useLocation();
  const scrollToTopRef = useRef(null);
  const currentPath = location.pathname;
  const pathSegments = currentPath.split("/")[1];
  const routePathCheck =
    pathSegments === "search" ||
    pathSegments === "profile" ||
    pathSegments === "content-list" ||
    pathSegments === "troubleshoot" ||
    pathSegments === "helpandsupport" ||
    pathSegments === "about-us"
      ? true
      : false;
  // Check if the current path matches the /video/:id pattern
  useEffect(() => {
    window.scrollTo(0, 0);
    scrollToTop();
  }, [currentPath]);

  const scrollToTop = () => {
    scrollToTopRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className={routePathCheck ? classes.notLogin : classes.main}>
      <Header />
      <div ref={scrollToTopRef} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainView;
