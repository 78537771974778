import React from "react";
import classes from "../../styles/quizLevels.module.css";

const ProgressBar = ({ percentage, onClick, clickable }) => {
  return (
    <div className={classes.progressBarContainer} onClick={onClick}>
      <div className={classes.progressBar} style={{ width: `${percentage}%` }}>
        <span className={classes.progressPercent}>{percentage}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
