import React, { useState,useEffect } from "react"; 
import Tempalte1 from "./templates/tempalte1" 
import Tempalte2 from "./templates/template2"
import {getQuizDetails} from "../../services/quizService"
import { useNavigate, useParams } from "react-router-dom";
import QuizCongratulation from "./templates/quizCongrats"
import QuizTryAgain from "./templates/quizTryAgain"
const QuizPlay = () => { 
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = 'Are you sure you want to leave?';
      event.preventDefault();
      event.returnValue = confirmationMessage; // Most browsers will show the default confirmation dialog
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const { quiz_id } = useParams();
  const navigate = useNavigate();
  const [userAns,setUserAns]=useState({})
  const [quizQuestion,setQuizQuestion]=useState([])
  const [currentQuestion,setCurrentQuestion]=useState([])
  const [currentQNumber,setcurrentQNumber]=useState(1)
  const [totalQues,setTotalQues]=useState(1)
  const [isSubmited,setIsSubmited]=useState(false)
  const [precentage,setPrecentage]=useState(0)
  const [caCount,setCaCount]=useState(0)
  
  const tryAgain=()=>{
    setIsSubmited(false)
    setUserAns({})
    setcurrentQNumber(1)
    setCurrentQuestion(quizQuestion[0])
    setPrecentage(0)
    setCaCount(0)
  }
  const quizSubmit=()=>{ 
    var correctAnsCount=0;
      for (let variable in quizQuestion) {
        if(userAns[variable]!=undefined){
          if(quizQuestion[variable].correct_ans==userAns[variable]){
            correctAnsCount=correctAnsCount+1
          }
        } 
     } 
     setCaCount(correctAnsCount)
     Math.round((correctAnsCount/totalQues)*100) 
     setPrecentage(Math.round((correctAnsCount/totalQues)*100)) 
     setIsSubmited(true)
  }

  const recordUserans=(q,a)=>{ 
    setUserAns({...userAns,[q]:a})
  }
  const nextQuestion=()=>{
    setCurrentQuestion(quizQuestion[(currentQNumber-1)+1])
    setcurrentQNumber(currentQNumber+1)
  }
  const prevQuestion=()=>{
    setCurrentQuestion(quizQuestion[(currentQNumber-1)-1])
    setcurrentQNumber(currentQNumber-1)
  }
  useEffect(()=>{
    getQuizDetails(quiz_id).then((res)=>{
      if(res.status){
        setQuizQuestion(res.data.quiz_questions)
        setCurrentQuestion(res.data.quiz_questions[0])
        setTotalQues(res.data.quiz_questions.length)
      }else{
        navigate('/')
      }
    })
  },[])
  return (
    <> 
    {
      !isSubmited && 
      <Tempalte1 
          quiz_id         = {quiz_id}
          question        = {currentQuestion} 
          currentQNumber  = {currentQNumber}
          totalQues       = {totalQues}
          nextQuestion    = {nextQuestion}
          prevQuestion    = {prevQuestion}
          recordUserans   = {recordUserans}
          userAns         = {userAns}
          quizSubmit      = {quizSubmit}
    />
    }
    {
       isSubmited &&   (precentage<=40 && precentage>=0)? <QuizTryAgain totalQues={totalQues} caCount={caCount} quiz_id={quiz_id} color='RED' tryAgain={tryAgain} precentage={precentage}/> : 
       (precentage<=79 && precentage>40)? <QuizTryAgain  totalQues={totalQues} caCount={caCount} quiz_id={quiz_id} color='GREEN' tryAgain={tryAgain} precentage={precentage}/> : 
       (precentage>=80 && <QuizCongratulation  totalQues={totalQues} caCount={caCount} quiz_id={quiz_id} tryAgain={tryAgain} precentage={precentage} />)
    } 
    </>
  );
};

export default QuizPlay;
