import "../Skeleton/skeleton.css"
const SearchSkeleton = () => {
    return (
      <div className="row mb-4">
      <div className="col-2 skeleton-header"></div> 
      <div className="col-2 skeleton-header"></div>
      <div className="col-2 skeleton-header"></div>
      <div className="col-2 skeleton-header"></div>
      <div className="col-2 skeleton-header"></div>
      <div className="col-2 skeleton-header"></div>
      <div className="col-2 skeleton-header"></div>
      <div className="col-2 skeleton-header"></div>
      <div className="col-2 skeleton-header"></div>
      <div className="col-2 skeleton-header"></div>
    </div>
    )

}
export default SearchSkeleton
