// actions/apiActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import {getMarketingBanners,getHomeData} from "../../services/bannerService"
import { getAllGrades } from "../../services/gradeService";
import {getUserDetails, profilePicUpload} from "../../services/userService"
import { getEnvUrl,getToken } from '../../utils/Api';
export const fetchMarketingBanners = createAsyncThunk('user/getMarketingBanners', async () => {
  const response = await getMarketingBanners(); 
  const data = await response;
  return data;
});

export const fetchGrades = createAsyncThunk('grade/getAllGrades', async () => {
  const response = await getAllGrades(); 
  const data = await response;
  return data;
});
export const FetchHomePage = createAsyncThunk('homepage/data', async () => {
  const response = await getHomeData(); 
  const data = await response;
  return data;
});

export const FetchUserData = createAsyncThunk('user/getUser', async () => {
  const response = await getUserDetails(); 
  const data = await response;
  return data;
});

export const fetchProfilePicture = createAsyncThunk('user/getProfilePic', async () => {
  const token = getToken();
  if (token === "undefined") {
    return { url: "/svgs/profile-Avathar.svg" };
  } else {
    const url = `${getEnvUrl()}/user/getProfilePic/${token}?width=100`;
    return { url };
  }
});
export const uploadProfilePicture = createAsyncThunk('user/uploadProfilePic', async (formData) => {
  const response = await profilePicUpload(formData);
  const imageUrl = URL.createObjectURL(formData.get('asset'));
  return { url: imageUrl, changed: true }; // returning the object to update the state
});