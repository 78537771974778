import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Image } from "react-bootstrap"; 
import classes from "../../styles/profile.module.css";
import {
  profilePicUpload, 
  updateUserProfile
} from "../../services/userService";
import { getEnvUrl, getToken } from "../../utils/Api";
import PasswordUpdate from "./passwordUpdate"; 
import {FetchUserData,fetchProfilePicture, uploadProfilePicture } from "../../redux/actions/homePageActions"
import { useSelector, useDispatch } from 'react-redux';

const ProfileData = () => {
  const dispatch = useDispatch();
  var userdata = "";
  const [serverError, setServerError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const userProfileData = useSelector(state => state.Home.UserData);
  const image = useSelector(state => state.Home.profilePicture);
  // const [image, setImage] = useState({
  //   url:
  //     getToken() === "undefined"
  //       ? "/svgs/profile-Avathar.svg"
  //       : `${getEnvUrl()}/user/getProfilePic/${getToken()}?width=100`,
  // });
  const [isEditable, setIsEditable] = useState(false);
  // Personal Details State
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    phone_number: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    address: "",
    phone_number: "",
  });
  const [initialFormData, setInitialFormData] = useState(formData);

  useEffect(() => {
    if(userProfileData?.status==undefined){ 
      dispatch(FetchUserData());
      dispatch(fetchProfilePicture());
    } 
  }, [dispatch]);  
  useEffect(() => { 
    if(userProfileData.status){
        userdata = {
        name: userProfileData?.data?.user_name,
        address: userProfileData?.data?.address,
        email: userProfileData?.data?.email,
        phone_number: userProfileData?.data?.phone_number,
      };
      setFormData(userdata);
        }
  }, [userProfileData]); 

  const handleInputChange = (e, formType) => {
    const { name, value } = e.target;

    if (formType === "personal") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let formErrors = {};
    if (!formData.name.trim()) formErrors.name = "Name is required";
    if (!formErrors.name && formData.name.length<3) formErrors.name = "Name should contain 3 or more characters";
    if (!formData.email.trim()) formErrors.email = "Email is required";
    if (!formData.address.trim())
      formErrors.address = "Address is required";
    if (!formData.phone_number.trim())
      formErrors.phone_number = "Mobile number is required";
    if (!formErrors.email && !regex.test(formData.email.trim()))
      formErrors.email = "Invalid email format";
    return formErrors;
  };

  const toggleEdit = async () => {
    if (isEditable) {
      const formErrors = validateForm();
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
      }
      updateUserProfile(formData).then((res) => {
        if (res.status) {
          alert(res.message);
          dispatch(FetchUserData());
          setServerError(false);
          setIsEditable(!isEditable);
          for (let key in formData) {
            if (typeof formData[key] === "string") {
              formData[key] = formData[key].trim();
            }
          }
          setInitialFormData(formData);
        } else {
          setServerError(true);
          setServerErrorMessage(res.errorMessage);
        }
      });
    } else {
      setInitialFormData(formData);
      setIsEditable(!isEditable);
    }
  };

  const cancelEdit = () => {
    setFormData(initialFormData);
    setIsEditable(false);
    setServerError(false);
    setErrors({});
  };
  const changeImage = () => {
    const img = document.getElementById("img");
    img.click();
  };

  const onImageChange = async (event) => {
    if (event.target.value && event.target.files[0].type.startsWith("image/")) {
      const selectedFile = event.target.files[0];
      const formData = new FormData();
      formData.append("asset", selectedFile);
       await dispatch(uploadProfilePicture(formData)); 
      // setImage({
      //   url: URL.createObjectURL(selectedFile),
      //   changed: true,
      // });
      setFile(selectedFile);
    }
  };

  return (
    <div>
      <div className={classes.profileHeader}>
        <div className={classes.avatarContainer}>
          <Image
            // src="/svgs/avathar.svg"
            src={image?.url || "/svgs/avathar.svg"}
            alt="Avatar"
            className={classes.avatar}
            id="profilePic"
          />
          <span className={classes.editOverlay} onClick={changeImage}>
            Edit
          </span>
          <input
            type="file"
            id="img"
            className="d-none"
            onChange={onImageChange}
            accept="image/*"
          ></input>
        </div>
        <div className={classes.profileInfo}>
          <h4 className={classes.username}>{formData.name}</h4>
          {/* <p className={classes.userDescription}>Maths Teacher, HSBC School</p> */}
        </div>
      </div>
      <div className={classes.personalDetails}>
        <h3>Personal Details</h3>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formName">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={(e) => handleInputChange(e, "personal")}
                  disabled={!isEditable}
                  className={classes.formControl}
                  maxLength={50}
                />
                {errors.name && (
                  <span className={classes.errorText}>{errors.name}</span>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formSubject">
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) => handleInputChange(e, "personal")}
                  disabled={!isEditable}
                  className={classes.formControl}
                  maxLength={50}
                />
                {errors.email && (
                  <span className={classes.errorText}>{errors.email}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formSchoolName">
                <Form.Control
                  type="text"
                  name="phone_number"
                  placeholder="Mobile Number"
                  value={formData.phone_number}
                  onChange={(e) => handleInputChange(e, "personal")}
                  disabled={!isEditable}
                  className={classes.formControl}
                  maxLength={15}
                />
                {errors.phone_number && (
                  <span className={classes.errorText}>
                    {errors.phone_number}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPhoneNumber">
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={(e) => handleInputChange(e, "personal")}
                  disabled={!isEditable}
                  className={classes.formControl}
                  maxLength={250}
                />
                {errors.address && (
                  <span className={classes.errorText}>{errors.address}</span>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                {serverError && (
                  <>
                    {" "}
                    <br />
                    <span className={classes.errorText}>
                      {serverErrorMessage}
                    </span>
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex gap-3">
            <Button
              variant="primary"
              onClick={toggleEdit}
              className={classes.profileEditButton}
            >
              {isEditable ? "Save" : "Edit"}
            </Button>
            {isEditable && (
              <Button
                variant="secondary"
                onClick={cancelEdit}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            )}
          </div>
        </Form>
      </div>
      <PasswordUpdate />
    </div>
  );
};

export default ProfileData;
