import React,{useEffect,useState} from "react";
import QuizBanner from "./quizBanner";
import CarouselView from "../HomePage/carousel";
import CardSection from "../HomePage/cardSection";
import { SubjectsData, data, data1 } from "../../utils/dummyData";
import {getDetailViewData} from "../../services/contentService"
import { useParams,useNavigate } from "react-router-dom";
import BodySkeleton from "../Skeleton/bodyskeleton"
import { getQuizDetails } from "../../services/quizService";
import BannerSkeleton from "../Skeleton/bannerSkeleton"
 
const QuizDetailView = () => {
  const navigate = useNavigate();
  const [bannerData, setBannerData] = useState([]);
  const [isLoading, setIsLoading]   = useState(true);
  const { id } = useParams();
  const [carousels,setCarousels]=useState([])
  const [isContentLoading, setContentLoading]   = useState(true);
  const [grade,setGrade]            = useState("")
  useEffect(() => {
    getQuizDetails(id).then((Response) => { 
    if (Response.status && Response.data) {
      const banners = Response.data;  
      setBannerData(banners);  
      setGrade(banners.grades[0])
      setTimeout(()=>{setIsLoading(false)},500)
    }else{
      navigate('/')
    }
  });
}, [id]);


  useEffect(()=>{ 
    if(grade){
      getDetailViewData(grade).then((rlt)=>{
        setCarousels(rlt.data)
        setTimeout(() => {
          setContentLoading(false)
        }, 1000);
      })
    } else{
      setContentLoading(false)
    }
  },[grade]) 
  return (
    <>
    {isLoading && <BannerSkeleton />}
    {!isLoading && <QuizBanner qdata={bannerData}/>} 
      {isContentLoading && <BodySkeleton />}
      {!isContentLoading && <>
        <CardSection />
      {carousels.map((carousel, index) => (
        <CarouselView key={index} title={carousel.title} data={carousel.data} />
      ))}</>}
      
    </>
  );
};

export default QuizDetailView;
