import React, { useEffect, useRef, useState } from "react";
import { useHistory,useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Image, Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Hls from "hls.js";
import { getMediaUrl, getEnvUrl } from "../../utils/Api";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import Styles from "../../styles/playVideo.module.css";
import { videoCardData } from "../../utils/dummyData";
import { getContentSource, getContentRelatedFcQuiz } from "../../services/contentService";
import {watchTime} from "../../services/userService"
const CryptoJS = require("crypto-js");
const secretKey = "4lb5urq3jbqSx0SxHO540t6sF7dzzzoH"; 
const PlayVideo = ({}) => { 
  const [contentData, setContentData] = useState([]);
  const [content_key, setContentKey] = useState('');
  const [contentName, setContentName] = useState('');
  const [description, setDescription] = useState('');
  const [fcQuizData, setFcQuizData] = useState([]);
  const [fcQuizBlob, setFcQuizBlob] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const navigatePage=(item)=>{
    if(item.contentType=='Quiz'){
        navigate(`/quizDetailView/${item.id}`)
      }else if(item.contentType=='Flash cards'){
        navigate(`/flashCardDetailView/${item.id}`)

      }
  }
 useEffect(()=>{

          fcQuizData?.forEach((fcQuiz) => {
            const baseUrl = getEnvUrl();
            const contenttype = fcQuiz.contentType;
            const url = contenttype === 'Flash cards'
              ? `${baseUrl}/fc/image/${fcQuiz.id}`
              : `${baseUrl}/quiz/quizImage/${fcQuiz.id}`;

            fetch(url)
              .then((res) => res.blob())
              .then((blob) => {
                const blobUrl = URL.createObjectURL(blob);
                setFcQuizBlob((prevBlobs) => ({
                  ...prevBlobs,
                  [fcQuiz.id]: blobUrl,
                })); 
              });
          });

 },[fcQuizData])

  useEffect(() => {
    if(content_key){
      getContentRelatedFcQuiz(content_key).then((Response) => {
        if (Response.status && Response.data) {
          setFcQuizData(Response.data)
        }
      }); 
    } 
  }, [content_key]);


  useEffect(() => {
    getContentSource(id).then((Response) => {
      if (Response.status && Response.data) {
        setContentKey(Response.data.id)
        const bytes = CryptoJS.AES.decrypt(Response.data.content_key, secretKey);
        const decryptedContentKey = bytes.toString(CryptoJS.enc.Utf8);
        setContentData(decryptedContentKey);
        setContentName(Response.data.name)
        setDescription(Response.data.description)
      }else{
        navigate('/')
      }
    });
  }, []);

  

  useEffect(() => {
    const videoElement = videoRef.current;
    const params = new URLSearchParams(location.search);
    const startTime = params.get('t');  
    if (videoElement && startTime) {
      const parsedTime = parseFloat(startTime);
      if (Number.isInteger(parsedTime)) { 
        videoElement.currentTime = parsedTime;
      }
    }
    const handleTimeUpdate = () => { 
      setCurrentTime(videoElement.currentTime);
    };

    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }
    var temp_time=0;
    const timer = setInterval(async () => {
      if(temp_time!=Math.ceil(videoElement.currentTime)){
        temp_time=Math.ceil(videoElement.currentTime)
        await watchTime({
          content_id:content_key,
          time:Math.ceil(videoElement.currentTime)
        })
      } 
    }, 5000); // 5 seconds 
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
      clearTimeout(timer);
    };
  }, [content_key]);

  //   const history = useHistory();
  const source = `${getMediaUrl()}/${contentData}`; 
  useEffect(() => {
    const video = videoRef.current;
    let hls;
    let player;

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(source);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const availableQualities = hls.levels.map((level) => level.height);
        player = new Plyr(video, {
          controls: [
            "play-large",
            "restart",
            "rewind",
            "play",
            "fast-forward",
            "progress",
            "current-time",
            "duration",
            "mute",
            "volume",
            "captions",
            "settings",
            "pip",
            "airplay",
            "fullscreen",
          ],
          quality: {
            default: availableQualities[0],
            options: availableQualities,
            forced: true,
            onChange: (newQuality) => updateQuality(newQuality, hls),
          },
        });
        // Set Plyr instance for debugging
        window.Plyr = player;
      });

      // Attach HLS.js to the window for debugging
      hls.attachMedia(video);
      window.hls = hls;
    } else {
      player = new Plyr(video, {
        controls: [
          "play-large",
          "restart",
          "rewind",
          "play",
          "fast-forward",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "captions",
          "settings",
          "pip",
          "airplay",
          "download",
          "fullscreen",
        ],
      });

      // Set Plyr instance for debugging
      window.Plyr = player;
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
      if (player) {
        player.destroy();
      }
    };
  }, [source]);

  const updateQuality = (newQuality, hls) => { 
    hls.levels.forEach((level, levelIndex) => {
      if (level.height === newQuality) {
        hls.currentLevel = levelIndex;
      }
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container fluid>
      <Row>
        <Col sm={9} className="p-0">
          <div className={Styles.videoOverlay}>
            <div className={Styles.videoHeader} onClick={handleGoBack}>
              {/* <button className={Styles.backButton}> */}
              <Image src="/svgs/video-back-arrow.svg" />
              {/* </button> */}
              {/* <button className={Styles.closeButton} >
                &times; Close
              </button> */}
            </div>
            <div className={Styles.videoContainer}>
              <video
                ref={videoRef}
                className="plyr-react plyr"
                autoPlay
                controls
                crossOrigin="anonymous"
                playsInline
              />
            </div>
            <h5 className="text-white pt-3 fs-3">{contentName}</h5>
            {/* <h3 className="text-white pt-3 fs-3">{description}</h3> */}
          </div>
        </Col>
        <Col sm={3} className="p-0">
        {!fcQuizData.length==0 && (
          <div className={Styles.cardSidebarHead}>
            <div className={Styles.cardTextMainHead}>
              <h5 className={Styles.cardHead}>Active Learning Tools</h5>
              <span className={Styles.cardSubHead}>
                ( A Visit to the Market)
              </span>
            </div>
              <>
              {fcQuizData.map((card, index) => (
              <div key={index} className="px-2">
                <Card className="border-0 bg-transparent">
                <div style={{minHeight:'150px'}}>
                <Card.Img
                    variant="top"
                    src={fcQuizBlob[card.id]}
                    onClick={()=>{navigatePage(card)}}
                    className={Styles.cardImg}
                  />
                </div>
                 
                 
                  <Image
                    src="/svgs/playRoundIcon.svg"
                    onClick={()=>{navigatePage(card)}}
                    className={Styles.playImg}
                  /> 
                  <Card.Body className="px-0">
                    <Card.Title className={Styles.cardTitle}>
                      {card.contentType}: {card.name}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>
            ))}
            </>
          </div>)}
        </Col>
      </Row>
    </Container>
  );
};

export default PlayVideo;
