import React,{useEffect,useState} from "react";
import Banner from "./banner";
import CarouselView from "../HomePage/carousel";
import CardSection from "../HomePage/cardSection";
import { SubjectsData, data, data1 } from "../../utils/dummyData";
import {getDetailViewData, getContentDetails} from "../../services/contentService"
import {  useParams,useNavigate } from "react-router-dom";
import BannerSkeleton from "../Skeleton/bannerSkeleton"
import BodySkeleton from "../Skeleton/bodyskeleton"
import {  } from "react-router-dom";
const VideoDetailView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [carousels,setCarousels]    = useState([])
  const [grade,setGrade]            = useState("")
  const [bannerData, setBannerData] = useState([]);
  const [isLoading, setIsLoading]   = useState(true);
  const [isContentLoading, setContentLoading]   = useState(true);
  const [gradeNamesData, setGradeNamesData] = useState([]);

 
  useEffect(()=>{
    if (grade!=="") {
      getDetailViewData(grade).then((rlt)=>{
      setCarousels(rlt.data)
      setTimeout(() => {
        setContentLoading(false)
      }, 1000);
    })
    }
  },[grade])
  useEffect(()=>{
    getContentDetails(id).then((Response) => {
      if (Response.status && Response.data) {
        const banners = Response.data;
        setBannerData(banners);
        setGrade(banners.grade_id[0])
        setGradeNamesData(banners.grade_names)
        setTimeout(()=>{setIsLoading(false)},1000)
      }else{
        navigate("/");
      }
    });
  },[id])

  return (
    <>
     {isLoading && <BannerSkeleton />}
     {!isLoading && <Banner bannerData={bannerData} gardeNames={gradeNamesData}/>}
      
      {isContentLoading && <BodySkeleton />}
      {!isContentLoading && <>
        <CardSection />
      {carousels && carousels.map((carousel, index) => (
        <CarouselView key={index} title={carousel.title} data={carousel.data} />
      ))}</>}
     
    </>
  );
};

export default VideoDetailView;
