import React, { useState } from "react";
import { Row, Col, Form, Button, Image } from "react-bootstrap";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import classes from "../../styles/profile.module.css";
import {updatePassword, logout} from "../../services/userService" 
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';

// import  "../../styles/profile.module.css";
const CssTextField = styled(TextField)({
    "& div.Mui-disabled": {
      color: "#F3F6FF",
    },
    "& label.MuiInputLabel-root": {
      color: "#F3F6FF",
      fontFamily: "Poppins",
      fontSize: "14px",
    },
  
    "& input.Mui-disabled": {
      "-webkit-text-fill-color": "#F3F6FF",
    },
    "& input.MuiOutlinedInput-input": {
      color: "#F3F6FF",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      borderColor: "#707a94",
    },
  
    "& label.Mui-focused": {
      // Label color
      color: "#F3F6FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#707a94",
    },
    "& .MuiOutlinedInput-root": {
      // Default border color
      "& fieldset": {
        borderColor: "#707a94",
      },
      "&:hover fieldset": {
        // Border color on hover
        borderColor: "#707a94",
      },
      "&.Mui-focused fieldset": {
        // Border color when focused
        borderColor: "#707a94",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#707a94 !important",
      },
      "&.Mui-disabled .MuiOutlinedInput-input": {},
    },
  });
const PasswordUpdate = () => {
     // Login Credentials State
  const navigate = useNavigate();
  const [cpwShow, setCpwShow] = useState(false);
  const [pwShow, setPwShow] = useState(false);
  const [cfmShow, setCfmShow] = useState(false);
  const [isLoginEditable, setIsLoginEditable] = useState(false);
  const [serverPwError, setServerPwError] = useState(false); 
  const [serverPwErrorMessage, setServerPwErrorMessage] = useState('');
  const initPwDetails={
    currentpassword: "",
    password: "",
    confirm_password:""
  }
  const [loginFormData, setLoginFormData] = useState(initPwDetails);
  const [initialLoginFormData, setInitialLoginFormData] =
    useState(loginFormData);
    const [loginErrors, setLoginErrors] = useState({
        currentpassword: "",
        password: "",
        confirm_password:""
      });
      const handleInputChange = (e, formType) => {
        var { name, value } = e.target; 
        value = value.replace(/\s/g, '')
         if (formType === "login") {
          setLoginFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setLoginErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
        }
      };
      const toggleLoginEdit = () => {
        if (isLoginEditable) {
          const formErrors = validateLoginForm();
          if (Object.keys(formErrors).length > 0) {
            setLoginErrors(formErrors);
            return;
          }
          updatePassword(loginFormData).then((res)=>{
            if(res.status){ 
              alert(res.message);
              setCpwShow(false);
              setPwShow(false);
              setCfmShow(false);
              setServerPwError(false) 
              setServerPwErrorMessage('')
              setLoginFormData(initPwDetails);
              setIsLoginEditable(!isLoginEditable);
              logout().then(() => {
                localStorage.clear();
                navigate("/login");
              });
            }else{ 
              setServerPwError(true) 
              setServerPwErrorMessage(res.errorMessage)
            }
          }) 
        } else {
          setLoginFormData(initPwDetails);
          setIsLoginEditable(!isLoginEditable);
        }
       
      };
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=]).{6,}$/;
      const validateLoginForm = () => {
        let formErrors = {};
        if (!loginFormData.currentpassword.trim()) formErrors.currentpassword = "Current password is required";
        if (!loginFormData.password.trim()) formErrors.password = "New password is required";
        if (!loginFormData.confirm_password.trim()) formErrors.confirm_password = "Confirm password is required";
        if(loginFormData.password && loginFormData.confirm_password && !passwordPattern.test(loginFormData.password)){
            formErrors.password = "Password must be 6-12 characters and include an uppercase, lowercase, number, and special character.";
            formErrors.confirm_password = "Confirm Password must be 6-12 characters and include an uppercase, lowercase, number, and special character.";
        }
        if(loginFormData.password && loginFormData.confirm_password && loginFormData.confirm_password!=loginFormData.password){
            formErrors.confirm_password = "New password and confirm password must match..!";
        }
        return formErrors;
      };
     const cancelLoginEdit = () => {
      setCpwShow(false);
      setPwShow(false);
      setCfmShow(false);
      setLoginFormData(initialLoginFormData);
      setIsLoginEditable(false);
      setLoginErrors({});
  };
return (
    <>
    <div className={classes.loginCredentials}>
        <h3>Update Password</h3>
        <Form>
        {isLoginEditable && <Row>
            <Col md={6}>
              <Form.Group> 
                <CssTextField
                  label="Current Password"
                  type={cpwShow?'text':'password'}
                  name="currentpassword"
                  id="custom-css-outlined-input"
                  value={loginFormData.currentpassword}
                  onChange={(e) => handleInputChange(e, "login")}
                  disabled={!isLoginEditable}
                  className={classes.textField}
                  fullWidth
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"  >
                        <IconButton onClick={()=>{setCpwShow(!cpwShow)}} edge="end" >
                          <i className={`${classes.eyeIcon} bi ${cpwShow ? 'bi-eye' : 'bi-eye-slash'}`}></i>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> 
                {loginErrors.currentpassword && (
                  <span className={classes.errorText}>
                    {loginErrors.currentpassword}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col md={6}></Col>
            <Col md={6}>
              <Form.Group>
                <CssTextField
                  label="New Password"
                  type={pwShow?'text':'password'}
                  name="password"
                  id="custom-css-outlined-input1"
                  value={loginFormData.password}
                  onChange={(e) => handleInputChange(e, "login")}
                  disabled={!isLoginEditable}
                  className={classes.textField}
                  fullWidth
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"  >
                        <IconButton onClick={()=>{setPwShow(!pwShow)}} edge="end" >
                          <i className={`${classes.eyeIcon} bi ${pwShow ? 'bi-eye' : 'bi-eye-slash'}`}></i>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {loginErrors.password && (
                  <span className={classes.errorText}>
                    {loginErrors.password}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <CssTextField
                  label="Confirm Password"
                  type={cfmShow?`text`:`password`}
                  name="confirm_password"
                  id="custom-css-outlined-input2"
                  value={loginFormData.confirm_password}
                  onChange={(e) => handleInputChange(e, "login")}
                  disabled={!isLoginEditable}
                  className={classes.textField}
                  fullWidth
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"  >
                        <IconButton onClick={()=>{setCfmShow(!cfmShow)}} edge="end" >
                          <i className={`${classes.eyeIcon} bi ${cfmShow ? 'bi-eye' : 'bi-eye-slash'}`}></i>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                />

                {loginErrors.confirm_password && (
                  <span className={classes.errorText}>
                    {loginErrors.confirm_password}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group  >
                {serverPwError && (
                   <> <br/>
                   <span className={classes.errorText}>
                    {serverPwErrorMessage} 
                  </span></>
                  
                )}
              </Form.Group>
            </Col>
          </Row>}
          <div className="d-flex gap-3">
            <Button
              variant="primary"
              onClick={toggleLoginEdit}
              className={classes.loginEditButton}
            >
              {isLoginEditable ? "Save" : "Update Password"}
            </Button>
            {isLoginEditable &&  <Button
              variant="secondary"
              onClick={cancelLoginEdit}
              className={classes.loginCancelButton}
            >
              Cancel
            </Button>}
          </div>
        </Form>
      </div>
    </>
)
}
export default PasswordUpdate;