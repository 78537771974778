import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { FastAverageColor } from "fast-average-color";
import classes from "../../styles/trendingVideo.module.css";
import TrendingVideoCard from "./trendingVideoCard";
import SearchSkeleton from "../Skeleton/searchSkeleton"
const TrendingVideo = ({ title, data }) => {
  const [colors, setColors] = useState({});
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setisLoading(false)
    }, 500);
    const fac = new FastAverageColor(); 
    const fetchColors = async () => {
      const newColors = {};
      for (let item of data) {
        try {
          const color = await fac.getColorAsync(item.img);
          newColors[item.id] = color.rgba;
        } catch (error) {
          console.error("Error fetching color:", error);
          newColors[item.id] = "rgba(0, 0, 0, 0.5)"; // default color if error occurs
        }
      }
      setColors(newColors);
    };

    if (data?.length > 0) {
      fetchColors();
    }

    return () => {
      fac.destroy();
    };
  }, []);

  return (
    <div className={classes.trendingVideoHead}>
        {isLoading && <Container> <SearchSkeleton />  </Container>  }
        {!isLoading && <Container>
        <div className={classes.trendingHead}>
          <div className={classes.trendingSubHead}>
            <span className={classes.trendingTitle}>{title}</span>
            {/* <span className={classes.trendingClick}>See all</span> */}
          </div>
          {data.map((item, i) => (
            <TrendingVideoCard
              key={item.id}
              index={i}
              item={item}
              averageColor={colors[item.id]}
              headingTitle={title}
            />
          ))}
        </div>
      </Container>}
      
    </div>
  );
};

export default TrendingVideo;
