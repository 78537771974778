import { getEnvUrl,getToken,axiosInstance } from "../utils/Api";

export const getQuizDetails = async (quiz_id) =>{ 
try {
    const response = await axiosInstance.get(`quiz/getQuizData?quizid=${quiz_id}`,{
        headers: { 
            Authorization: `Bearer ${getToken()}`,
        }
    });
    return response.data;
    } catch (error) {
    throw error;
    } 
}   


