import React, { useState } from "react";
import classes from "../../styles/smartLearn.module.css";
import { colors } from "../../utils/dummyData";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const SmartLearnCarouselCard = ({
  index,
  item,
  averageColor,
  headingTitle,
  type,
}) => { 
  const navigate = useNavigate();
  const navigatePage=(item)=>{
    if(item.item_type=='VIDEO'){
      navigate(`/videoDetailView/${item.id}`)
    }else if(item.item_type=='QUIZ'){
      navigate(`/quizDetailView/${item.id}`)
    }else if(item.item_type=='FLASH_CARD'){
      navigate(`/flashCardDetailView/${item.id}`)
      
    }
  }

  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const getBackgroundColor = (index) => {
    return colors[index % colors.length];
  };

  const titleColor = {
    "Flash Cards": classes.curveHeadOne,
    Quiz: classes.curveHeadTwo,
    Test: classes.curveHeadThree,
    Spellings: classes.curveHeadFour,
    English:classes.curveHeadOne,
    EVS:classes.curveHeadTwo,
    "GL & ST":classes.curveHeadThree,
    Mathematics:classes.curveHeadFour,
    Rhymes:classes.curveHeadTwo,
    Stories:classes.curveHeadThree
  };
  return (
    <div
      className={type === "noCarousel" ? classes.noCarouselCard : classes.card}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hovered && (item.name || item.title) && (
        <div
          className={classes.overlay}
          style={{
            backgroundColor: averageColor,
          }}
        ></div>
      )}
      <div
        className={`${
          type === "noCarousel"
            ? classes.noCarouselContainer
            : classes.imageContainer
        }`}
      >
        <div style={{minHeight:'142px'}}>
        <img src={item.img} alt="Img" className={`${classes.image} `} onClick={()=>{navigatePage(item)}}/>

        </div>
        
        <div className={classes.playHead}>
          {item.name && item.title && (
            <img
              src="/svgs/play_circle.svg"
              alt="play"
              className={classes.playIcon}
         
            />
          )}

          {item?.title && (
            <div
              className={titleColor[headingTitle]}
              // className={classes.curveHeadOne}
              // style={{ borderTop: `29px solid ${getBackgroundColor(index)}` }}
            >
              <span className={classes.gameTitle}>{item?.title} </span>
            </div>
          )}
        </div>

        <div className={classes.nameHead}>
          {item?.name && <div className={classes.content}>{item?.name}</div>}
        </div>
      </div>
    </div>
  );
};

export default SmartLearnCarouselCard;
