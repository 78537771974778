import { getEnvUrl,getToken,axiosInstance } from "../utils/Api";
 
 
export async function getAns(qus) {
  try {
    const url = `/ai/qa?qus=${qus}`;
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`, 
      },
       responseType: 'blob'
    };
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Error occurred while fetching marketing banners:', error);
    return false;
  }
}