import axios from "axios";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => { 
    if ((!response.data?.status && response.data?.errorMessage === "Invalid token")||
        (!response.data?.status && response.data?.errorMessage === "Permission denied") ||
        (!response.data?.status && response.data?.message === "User logged out..!")) {
        localStorage.clear();  
        window.location.reload();
    }
    return response;
  },
  (error) => { 
    const status = error.response ? error.response.status : null; 
    if (status === 401) {
      localStorage.clear();  
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

 
export const getEnvUrl = () => { 
  return process.env.REACT_APP_API_CMS;
};  
export const getMediaUrl = () => { 
  return process.env.REACT_APP_MEDIA_URL;
}; 
export const getToken = () => {
    return localStorage.getItem("token");
};
export const isUserLogin = () => {
  if(localStorage.getItem("token")){
      return true;
  }else{
    return false;
  }
};
axiosInstance.defaults.baseURL = getEnvUrl();
export {axiosInstance};