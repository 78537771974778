import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import { getEnvUrl } from "../../utils/Api";
import styles from "../../styles/HomePage/homepage.module.css";
import { useWindowSize } from "../../utils/windowSize";
import BannerSkeleton from "../Skeleton/bannerSkeleton"
import {fetchMarketingBanners} from "../../redux/actions/homePageActions"
import { useSelector, useDispatch } from 'react-redux';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const HomeBanner = () => {
  const dispatch = useDispatch(); 
  const { width: windowSize } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const [bannerBlobs, setBannerBlobs] = useState({});
  const banners = useSelector(state => state.Home.marketing_banners); 
  useEffect(() => {
    if(banners?.status==undefined){ 
      dispatch(fetchMarketingBanners());
    } 
  }, [dispatch]);
  useEffect(() => { 
    if(banners?.status!=undefined){ 
            if (banners.status) {
        setTimeout(()=>{ setIsLoading(false)},500) 
        const data = banners.data;
        setBannerData(data); 
        data?.forEach((banner) => {
          fetch(`${getEnvUrl()}/content/marketingBanner/${banner.id}`)
            .then((res) => res.blob())
            .then((blob) => {
              const blobUrl = URL.createObjectURL(blob);
              setBannerBlobs((prevBlobs) => ({
                ...prevBlobs,
                [banner.id]: blobUrl,
              }));
            });
        });
      }
    } else{
      setTimeout(()=>{ setIsLoading(false)},500) 
    }
  }, [banners]);

  const navigate = useNavigate();

  return (
    <div className={`${styles.homeHead}`}>
      {isLoading && <BannerSkeleton />}

      {!isLoading && (
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={4000}
          infinite={true}
          showDots={true}
          containerClass="corousal-container"
          slidesToSlide={1}
          renderDotsOutside={true}
          dotListClass={styles.customDotListStyle}
        >
          {Array.isArray(bannerData) && bannerData.length > 0 ? (
            bannerData.map((item, i) => (
              <div className={styles.bannerSlide} key={i}>
                {bannerBlobs[item.id] ? (
                  <img src={bannerBlobs[item.id]} alt={`Slide ${i + 1}`} />
                ) : (
                  <div></div>
                )}
                <div className={styles.bannerContent}>
                  <span className={styles.quiz}>
                    {item.content_type === "FLASH_CARD"
                      ? "FLASH CARD"
                      : item.content_type}
                  </span>
                  <div>
                    <span className={styles.bannerHeading}>
                      {item.content_name}
                    </span>
                    <br></br>
                    <span className={styles.mathematic}>
                      {item.description}
                    </span>
                  </div>
                  <div className={styles.homeBannerPlayButton}>
                    <Button
                      className={`${styles.homeBannerButton}`}
                      onClick={() => {
                        if (item.content_type == "VIDEO") {
                          navigate(`/videoDetailView/${item.content_id}`);
                        } else if (item.content_type == "QUIZ") {
                          navigate(`/quizDetailView/${item.content_id}`);
                        } else if (item.content_type == "FLASH_CARD") {
                          navigate(`/flashCardDetailView/${item.content_id}`);
                        }
                      }}
                    >
                      <img
                        src="/svgs/play_circle.svg"
                        className={styles.homeBannerPlayImg}
                        alt="Play"
                      />
                      Play now
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <div className="skeleton-header"></div>
            </div>
          )}
        </Carousel>
      )}
    </div>
  );
};

export default HomeBanner;
