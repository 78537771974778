import React, { useState, useEffect } from "react";
import HomeBanner from "./homeBanner";
import CarouselView from "./carousel";
import MessageCard from "./messageCard";
import BodySkeleton from "../Skeleton/bodyskeleton";
import { FetchHomePage } from "../../redux/actions/homePageActions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoginSuccess from "../LoginPage/loginSuccessPopup";

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginMessage } = location.state || {};
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState(false);
  const [carousels, setCarousels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const HomePageData = useSelector((state) => state.Home.HomeData);
  useEffect(() => {
    if (HomePageData?.status == undefined) {
      dispatch(FetchHomePage());
    }
  }, [dispatch]);
  useEffect(() => {
    if (HomePageData.status) {
      setCarousels(HomePageData.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [HomePageData]);
  useEffect(() => {
    if (loginMessage === "User loggedin") {
      setShowMessage(true);
      const timer = setTimeout(() => {
        setShowMessage(false);
        navigate(location.pathname, { replace: true, state: {} });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [loginMessage]);
  return (
    <>
      <HomeBanner />
      {showMessage && <LoginSuccess />}
      {isLoading && <BodySkeleton />}
      {!isLoading && (
        <>
          {carousels &&
            carousels.map((carousel, index) => (
              <CarouselView
                key={index}
                title={carousel.title}
                data={carousel.data}
              />
            ))}
        </>
      )}

      <MessageCard />
    </>
  );
};

export default HomePage;
