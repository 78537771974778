import React, { useEffect } from "react";
import classes from "../../styles/login.module.css";
import { Image } from "react-bootstrap";

const LoginSuccess = () => {
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className={`${classes.loginSuccessWrap}`}>
      <div
        className={`${classes.loginSuccess} bg-white text-black d-flex justify-content-between align-items-center`}
      >
        <div className="">
          <Image src="/svgs/login_Checklist.svg" alt="" />
        </div>
        <div className="">
          <p className="m-0">Log in successful!</p>
          <h4 className="m-0">Welcome back</h4>
        </div>
      </div>
    </div>
  );
};
export default LoginSuccess;
