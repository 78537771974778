import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import classes from "../../styles/gradeFilters.module.css";
import GradeFilters from "./gradeFilters";
import { useNavigate, useLocation } from "react-router-dom";
import SmartLearnCarouselView from "../SmartLearnPage/smartLearnCarouselView";
import GradesHeader from "../SmartLearnPage/gradesHeader";
import { getAllGrades } from "../../services/gradeService";
import { getEnvUrl } from "../../utils/Api";
import { getQuizVideosFcBySubjectId, getAllQuizFc } from "../../services/contentService";
const FlashCards = () => {
  const [activeTab, setActiveTab] = useState('');  
  const [subjectName, setSubName] = useState('');  
  const [grades, setGrades] = useState([]);
  const [filteredContentData, setFilteredData] = useState([]);
  const changeGrade=(grade_id)=>{
    setActiveTab(grade_id)
  }
  useEffect(() => {
    getAllGrades().then((res) => {
      if (res.status) {
        const data = res.data.map((obj) => ({
          id: obj.grade_id,
          img: `${getEnvUrl()}/content/getThumbnail?moduleName=grades&id=${obj.grade_id}`,
        }));
        setGrades(data);
        setActiveTab(data[0].id)
      }
    });     
  }, []);
  useEffect(()=>{
      if(activeTab!='' && message!="flash-cards" && message!="Quiz"){
        getQuizVideosFcBySubjectId(activeTab,message).then((res) => {
        if (res.status===true) {
           setFilteredData(res.data);
           setSubName(res.data[0].subjectName) 
        }
      });
    }else{
        getAllQuizFc(activeTab,message).then((res) => {
        if (res.status===true) {
           setFilteredData(res.data);
           setSubName(res.data[0].title) 
        }
      });
    } 
  },[activeTab])

  const navigate = useNavigate();
  const location = useLocation();
  const { message, routeType } = location.state || {};
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 100);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleBackClick = () => navigate(-1);

  return (
    <>
      <div className={classes.flashCardHead}>
        <Container fluid className={classes.gradeContainer}>
          <div className={classes.gradeBorderHead}>
            <Image
              src="/svgs/arrow_drop_down_circle.svg"
              alt="ArrowCircle"
              onClick={handleBackClick}
              className={classes.backArrow}
            />
            <span className={classes.gradeCardHead}>{subjectName}</span>
          </div>
        </Container>
      </div>
      <div className={scrolled ? classes.gradeNavHead : classes.gridFilterHead}>
        {scrolled ? <GradesHeader  gradesData={grades} activeTab={activeTab} changeGrade={changeGrade} /> : <GradeFilters  gradesData={grades} activeTab={activeTab} changeGrade={changeGrade} />}
      </div>
      <div>
        {filteredContentData.map((item, index) => (
          <React.Fragment key={index}>
            <SmartLearnCarouselView
              title={item.concept_name}
              data={item.data}
              type={"noCarousel"}
              routeName={"flash-cards"}
              routeType={routeType}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default FlashCards;