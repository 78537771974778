import React from "react";
import classes from "../styles/HomePage/homepage.module.css";
import { Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.footerBgHead}>
      <Container fluid className={classes.footerContainerHead}>
        <div className={classes.footerHead}>
          <div className={classes.leftSideHead}>
            <div className={classes.companyHead}>
              <h2 className={classes.companyTitle}>Company</h2>
              <span
                className={classes.aboutTitle}
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About us
              </span>
              {/* <span className={classes.carrersTitle}>Careers</span> */}
            </div>
            {/* <div className={classes.appHead}>
              <h2 className={classes.appTitle}>View App in</h2>
              <div className={classes.appHead2}>
                <span className={classes.languageTitle}>
                  <Image
                    src="/svgs/check.svg"
                    alt="Right Tick"
                    className={classes.tickImg}
                  />
                  English{" "}
                </span>
                <span className={classes.languageTitle}>Telugu</span>
                <span className={classes.languageTitle}>Tamil</span>
                <span className={classes.languageTitle}>Kannada</span>
              </div>
            </div> */}
            <div className={classes.needHead}>
              <h2 className={classes.needTitle}>Need Help?</h2>
              <span
                className={classes.helpTitle}
                onClick={() => {
                  navigate("/helpandsupport");
                }}
              >
                Help & Support​
              </span>
              <span
                className={classes.feedbackTitle}
                onClick={() => {
                  navigate("/troubleshoot");
                }}
              >
                Troubleshoot ​
              </span>
            </div>
            <div className={classes.needHead}>
              <h2 className={classes.needTitle}>Content</h2>
              <span
                className={classes.helpTitle}
                onClick={() => {
                  navigate("/content-list/1");
                }}
              >
                Content List
              </span>
            </div>
          </div>
          <div className={classes.rightSideHead}>
            <h2 className={classes.connectTitle}>Connect with Us</h2>
            <div className={classes.iconsHead}>
              <a
                href="https://www.facebook.com/mytalkingtree/?Ref=Pages_You_Manage%20"
                className="text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src="/svgs/facebook.svg" alt="facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/mytalkingtree/"
                className="text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src="/svgs/linkedIn.svg" alt="linkedIn" />
              </a>
              <a
                href="https://www.instagram.com/mytalkingtree/"
                className="text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src="/svgs/instagram.svg" alt="instagram" />
              </a>
              <a
                href="https://twitter.com/MyTalkingTree10?t=VL7JAVoHQbq8_zu-52WVWg&s=08"
                className="text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src="/svgs/twitter.svg" alt="twitter" />
              </a>
            </div>
          </div>
          <div className={classes.lastRow}>
            © 2024 .All Rights Reserved. | Terms Of Use Privacy Policy FAQ
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
