import { Col, Container, Image, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { cardData } from "../../utils/dummyData";
import Styles from "../../styles/HomePage/homepage.module.css";

const CardSection = () => {
  return (
    <Container fluid className={`${Styles.cardConatiner} mt-4`}>
      <Row>
        <Col className="ps-2">
          <h5 className={Styles.cardHead}>
            Active Learning Tools ( A Visit to the Market)
          </h5>
        </Col>
      </Row>
      <Row>
        {cardData.map((card, index) => (
          <Col lg={3} md={3} sm={6} xs={12} key={index} className="px-2">
            <Card className="border-0 bg-transparent">
              <Card.Img
                variant="top"
                src={card.imgSrc}
                className={Styles.cardImg}
              />
              <Image src="/svgs/playRoundIcon.svg" className={Styles.playImg} />
              <Card.Body className="px-0">
                <Card.Title className={Styles.cardTitle}>
                  {card.title}
                </Card.Title>
                <Card.Text className={Styles.cardPara}>{card.text}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CardSection;
