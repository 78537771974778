import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Styles from "../../styles/HomePage/homepage.module.css";
import { getEnvUrl } from "../../utils/Api";
import { useNavigate, useParams } from "react-router-dom";

const FlashCardBanner = ({bdata}) => {
  const bannerData = bdata;
  const [isLoading, setIsLoading]   = useState(true);
  const { id }    = useParams();
  const navigate  = useNavigate(); 
  const bannerUrl = `${getEnvUrl()}/fc/banner/${bannerData.id}`; 
  const bannerStyle = {
    backgroundImage: `url(${bannerUrl})`,
    height: '600px',
    backgroundSize: 'cover', // Ensure the background image covers the entire area
    backgroundPosition: 'center', // Center the background image
  };
  return (
    <>
        <div className={Styles.Banner1} style={bannerStyle}>
         
        <Container fluid>
          <Row>
            <Col xl={4} lg={6} md={8} className={Styles.BannerContent}>
              <span className={Styles.EVS}>FLASH CARD - {bannerData.grade_names}</span>
              <h1>{bannerData.name}</h1>
              <p className={Styles.BannerPara}>
                {bannerData.description}
              </p>
              <div className={Styles.ButtonHeads}>
                <div className={Styles.ParentPlayButton}>
                  <Button
                    onClick={() => {
                      navigate(`/flashcard/${bannerData.id}`);
                    }}
                    className={`${Styles.PlayButton} `}
                  >
                    <img src="/svgs/play_circle.svg" />
                    Start
                  </Button>
                </div>
                {/* <div className={Styles.ParentPlusButton}>
                  <Button className={Styles.PlusButton}>
                    <img src="/svgs/plusIcon.svg" className="me-2" />
                    Add to favourite
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FlashCardBanner;
