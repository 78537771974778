import React, { useState } from "react";
import { Tab, Nav, Row, Col, Container, Image } from "react-bootstrap";
import classes from "../../styles/profile.module.css";
import ProfileData from "./profileData";
import LoginDevices from "./loginDevices";
import { logout } from "../../services/userService";
import { useNavigate } from "react-router-dom"; 
const tabs = [
  {
    key: "profile",
    image: "/svgs/profile-Avathar.svg",
    title: "Profile",
    description: "Manage the profile pic & name",
  },
  {
    key: "devices",
    image: "/svgs/device.svg",
    title: "Devices",
    description: "Manage logined devices",
  },
  {
    key: "parentalLock",
    image: "/svgs/lock.svg",
    title: "Parental Lock",
    description: "Parental Controls",
  },
  {
    key: "helpAndSupport",
    image: "/svgs/help.svg",
    title: "Help & Support",
    description: "Help Centre",
  },
];

const ProfilePage = () => {
  const navigate = useNavigate(); 
  const [activeTab, setActiveTab] = useState("profile"); 
  const renderNavItem = ({ key, image, title, description }) => (
    <Nav.Item className={classes.profileNavItemHead} key={key}>
      <Nav.Link
        eventKey={key}
        onClick={() => setActiveTab(key)}
        className={
          activeTab === key ? classes.profileLinkHead : classes.notActive
        }
      >
        <div className={classes.flexContainer}>
          <div className={classes.profileSubHead}>
            <div className={classes.profileImage}>
              <Image src={image} alt={title} width={"100%"} height={"100%"} />
            </div>
            <div>
              <div className={classes.profileName}>{title}</div>
              <div className={classes.profilePara}>{description}</div>
            </div>
          </div>
          <Image
            src="/svgs/arrow_back.svg"
            alt="Arrow"
            className={classes.backIcon}
          />
        </div>
      </Nav.Link>
    </Nav.Item>
  );

  const hanldeLogout = async () => {
    if (window.confirm('Are you sure you want to logout?')) {
       logout().then(()=>{
          localStorage.clear()
          navigate('/login')
        })
    }
  };
  return (
    <div fixed="top" className={`mb-3 container-fluid ${classes.profileHead}`}>
      <Container fluid className={classes.profileContainer}>
        <Row className={classes.profileRowHead}>
          <Col xs={12} md={4} sm={3}>
            <Nav variant="pills" className={classes.flexColumn}>
              {tabs.map(renderNavItem)}
            </Nav>
            <div className={classes.logOutButtonHead} onClick={()=>{hanldeLogout()}}>
              <button>Log Out</button>
            </div>
          </Col>
          <Col xs={12} md={1} sm={1} className={classes.verticalLine}>
            <div className={classes.verticalLineSubHead}></div>
          </Col>
          <Col xs={12} md={7} sm={8}>
            <Tab.Content>
              {activeTab === "profile" && <ProfileData />}
              {activeTab === "devices" && <LoginDevices/>}
              {activeTab === "parentalLock" && (
                <div style={{ color: "white" }}>
                  <h3>Parental Lock</h3>
                  <p>Parental Lock.</p>
                </div>
              )}
              {activeTab === "helpAndSupport" && (
                <div style={{ color: "white" }}>
                  <h3>Help</h3>
                  <p>Help and Support.</p>
                </div>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfilePage;
