import React, { useState, useEffect,useRef } from 'react';
import classes from "../../styles/aiScreen.module.css";
import { Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {getAns} from "../../services/aiService"
const AiScreen = () => {  
  const navigate = useNavigate();
  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [soundPlay, setSoundPlay]     = useState(false);
  const [asnSoundLoading, setSoundLoading] = useState(false);  
  const ClickAudioRef = useRef(null);
  const play_somthing =async ()=>{ 
    ClickAudioRef.current = new Audio(`/sounds/somthing_went_wrong.mp3`);
    ClickAudioRef.current.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  }
  const getResponse =async (finalTranscript)=>{
    getAns(finalTranscript).then(async (response) => {  
      try {
       if(response.type=='audio/mpeg'){
        const url = URL.createObjectURL(response); 
        const newAudio = new Audio(url);
        await newAudio.play();  // It's good practice to await the play method since it returns a promise
        setSoundPlay(true);
        newAudio.addEventListener('ended', () => {
          setSoundPlay(false);
        });
       }else{
        play_somthing()
       } 
      } catch (error) {
        console.error('Error occurred while processing the audio:', error);
        setSoundPlay(false);
        setSoundLoading(false);
        play_somthing()
      }
    }).catch((e) => {
      console.error('Error occurred while getting the answer:', e);
      setSoundLoading(false);
      play_somthing()
    });
  }
  useEffect(() => {
    // Check if the browser supports the Web Speech API
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) { 
      const recognitionInstance = new SpeechRecognition(); 
      recognitionInstance.continuous = false; // Keep capturing speech as long as possible
      recognitionInstance.interimResults = false; // Capture results as they come in
      recognitionInstance.lang = 'en-US'; // Set the language (e.g., 'en-US') 
      recognitionInstance.onresult = (event) => { 
        let finalTranscript = '';
        for (let i = 0; i < event.results.length; i++) {
          const transcriptPart = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcriptPart + ' ';
          }
        } 
        setTranscript(finalTranscript);
        getResponse(finalTranscript);
      };

      recognitionInstance.onend = () => {
        setIsListening(false); 
      };

      setRecognition(recognitionInstance);
    } else {
      alert('Your browser does not support speech recognition. Please try Chrome or Firefox.');
    }
  }, []);

  const startListening = () => {
    if (recognition) {
      recognition.start();
      setIsListening(true);
      setTranscript('')
    }
  };

  const stopListening = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
    }
  };
  return (
    <div className={classes.aiScreenBgDot}>
      <div className={classes.aiScreenBg}>
        <Container fluid className="px-5" style={{ height: "100vh" }}>
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "100%" }}
          >
            <div className="d-flex justify-content-between align-items-center pt-5">
           
              <Image onClick={()=>{navigate('/')}} src="/pngs/Logo.png" alt="Logo" role="button" />
              {/* <Image
                src="/pngs/AI-Screens/AI-Close.png"
                alt="closeIcon"
                role="button"
              /> */}
            </div>
            <div className={classes.leafImgHead}>
              <Image
                src="/pngs/AI-Screens/aiScreenLeaf.png"
                alt="Leaf"
                className={classes.leafImg}
                />
            </div>
            <div className={classes.inputHeads}>
              <Image
              onClick={isListening ? stopListening : startListening}
                src="/pngs/AI-Screens/Ai-Mic-Speaking.png"
                alt="Mic"
                className={classes.micSpeaking}
              />
              <input
                type="text"
                placeholder="Your Questions will be Show here "
                autoComplete="nope"
                className={classes.inputHead}
                value={transcript}
              />
            {isListening?
              <Image style={{width:'155px'}}
                src="https://i.pinimg.com/originals/62/bf/8d/62bf8d371ee7a6d23654457178b10067.png"
                className={classes.audio}
              />:<Image
                src="/pngs/AI-Screens/AI-Audio-Line-Spectrum.png"
                className={classes.audio}
              />} 

            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AiScreen;
