import React,{useEffect,useState} from "react";
import FlashCardBanner from "./flashCardBanner";
import CarouselView from "../HomePage/carousel";
import CardSection from "../HomePage/cardSection";
import { SubjectsData, data, data1 } from "../../utils/dummyData";
import {getDetailViewData} from "../../services/contentService"
import { useParams,useNavigate } from "react-router-dom";
import BodySkeleton from "../Skeleton/bodyskeleton"
import { getFcDetails } from "../../services/flashCardService";
import BannerSkeleton from "../Skeleton/bannerSkeleton" 
const FlashCardsDetailView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [carousels,setCarousels]=useState([])
  const [isContentLoading, setContentLoading]   = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const [grade,setGrade]            = useState("")
  const [isLoading, setIsLoading]   = useState(true);
  useEffect(()=>{ 
    if (grade) {
      getDetailViewData(grade).then((rlt)=>{
        setCarousels(rlt.data)
        setTimeout(() => {
          setContentLoading(false)
        }, 1000);
      })
    }else{
      setContentLoading(false) 
    } 
  },[grade]) 
  useEffect(() => {
    getFcDetails(id).then((Response) => { 
      if (Response.status && Response.data) {
        const banners = Response.data;    
        setBannerData(banners)
        setGrade(banners?.grades[0])
        setTimeout(()=>{setIsLoading(false)},500)
      }else{
        navigate('/')
      }
    });
  }, [id]);
  return (
    <>
    {isLoading && <BannerSkeleton />}
    {!isLoading && <FlashCardBanner bdata={bannerData}/>}
    {isContentLoading && <BodySkeleton />}
      {!isContentLoading && <>
        <CardSection />
      {carousels && carousels.map((carousel, index) => (
        <CarouselView key={index} title={carousel.title} data={carousel.data} />
      ))}</> }
      
    </>
  );
};

export default FlashCardsDetailView;
