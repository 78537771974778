import React, { useState } from "react";
import { colors } from "../../utils/dummyData";
import classes from "../../styles/trendingVideo.module.css";
import { useNavigate } from "react-router-dom";
const TrendingVideoCard = ({ index, item, averageColor, headingTitle }) => {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  }; 
  const handleMouseLeave = () => {
    setHovered(false);
  }; 
  const getBackgroundColor = (index) => {
    return colors[index % colors.length];
  };
  const navigatePage=(item)=>{
    if(item.item_type=='VIDEO'){
      navigate(`/videoDetailView/${item.id}`)
    }else if(item.item_type=='QUIZ'){
      navigate(`/quizDetailView/${item.id}`)
    }else if(item.item_type=='FLASH_CARD'){
      navigate(`/flashCardDetailView/${item.id}`)
      
    }
  }
  return (
    <div
      className={classes.trendingCardHead}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hovered && (item.name || item.title) && (
        <div
          className={classes.overlay}
          style={{
            backgroundColor: averageColor,
          }}
        ></div>
      )}
      <div className={classes.trendingContainer}>
        <div style={{minHeight:'140px'}}>
        <img onClick={()=>{navigatePage(item)}} src={item.img} alt="Img" className={classes.image} role="button" />

        </div>
        
        <div className={classes.playHead}>
          {item.name && item.title && (
            <img
              src="/svgs/play_circle.svg"
              alt="play"
              className={classes.playIcon}
            />
          )}
          {hovered && headingTitle === "Trending Videos" && index >=4 && (
            <div className={classes.hoverPlay}></div>
          )}
          {item?.title && (
            <div
              className={classes.curveHead}
              style={{ borderTop: `29px solid ${getBackgroundColor(index)}` }}
            >
              <span className={classes.gameTitle}>{item?.title}</span>
            </div>
          )}
        </div>

        <div className={classes.nameHead}>
          {item?.name && <div className={classes.content}>{item?.name}</div>}
        </div>
      </div>
    </div>
  );
};

export default TrendingVideoCard;
