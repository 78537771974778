import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Styles from "../../styles/HomePage/homepage.module.css";
import { getEnvUrl } from "../../utils/Api";
import { useNavigate, useParams } from "react-router-dom";
import { getContentDetails } from "../../services/contentService";
const Banner = ({ bannerData,gardeNames }) => {
  // const [bannerData, setBannerData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  //   useEffect(() => {
  //   getContentDetails(id).then((Response) => {
  //     if (Response.status && Response.data) {
  //       const banners = Response.data;
  //       setBannerData(banners);
  //     }
  //   });
  // }, [id]);
  return (
    <>
        <div className={Styles.Banner1} style={{backgroundImage: `url(${getEnvUrl()}/content/video/thumbnail/b/${bannerData.id}?width=1200&v=0.8621084816597533)`}}>
        <Container fluid>
          <Row>
            <Col xl={4} lg={6} md={8} className={Styles.BannerContent}>
              <span className={Styles.EVS}>
                VIDEO-{gardeNames.join(', ')}
              </span>
              <div>
              <span className={Styles.bannerHeading}>{bannerData.name}</span>
              <br></br>
              <span className={Styles.mathematic}>
                {bannerData.description}
              </span>
              </div>
              <div className={Styles.ButtonHeads}>
                <div className={Styles.ParentPlayButton}>
                  <Button
                    onClick={() => {
                      navigate(`/video/${bannerData.content_id}`);
                    }}
                    className={`${Styles.PlayButton} `}
                  >
                    <img src="/svgs/play_circle.svg" />
                    Play Now
                  </Button>
                </div>
                <div className={Styles.ParentPlusButton}>
                  <Button className={Styles.PlusButton}>
                    <img src="/svgs/plusIcon.svg" className="me-2" />
                    Add to favourite
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Banner;
