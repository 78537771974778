import React, { useEffect, useState, useRef } from "react";
import classes from "../../../styles/quizLevels.module.css";
import { Button, Image } from "react-bootstrap";
import ProgressBar from "../../Cards/progressBar";
import { getEnvUrl } from "../../../utils/Api";
import { getMediaUrl } from "../../../utils/Api";
import { useNavigate } from "react-router-dom";
const Tempalte = ({
  quiz_id,
  question,
  currentQNumber,
  totalQues,
  nextQuestion,
  prevQuestion,
  recordUserans,
  userAns,
  quizSubmit,
}) => {
  const navigate = useNavigate();
  const ans = ["a", "b", "c", "d"];
  const answers = [
    question?.option_a,
    question?.option_b,
    question?.option_c,
    question?.option_d,
  ];
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isScreenBig, setIsScreenBig] = useState(false);
  const [backgroundImageStyle, setbackgroundImageStyle] = useState({});
  const audioRef = useRef(null);
  const quitQuiz = () => {
    if (window.confirm(`Are you sure you want to quit the quiz?`)) {
      navigate(`/quizDetailView/${quiz_id}`);
    }
  };
  useEffect(() => {
    if (userAns[currentQNumber - 1] != undefined) {
      setSelectedAnswer(answers[ans.indexOf(userAns[currentQNumber - 1])]);
    }
    audioRef.current = new Audio(`${getMediaUrl()}/${question.audio_key}`);
    setTimeout(() => {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }, 500);
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [question.id]);

  const handleAnswerClick = (answer, index) => {
    recordUserans(currentQNumber - 1, ans[index]);
    setSelectedAnswer(answer);
  };
  const handleScreenSize = () => {
    setIsScreenBig(true);
  };

  useEffect(() => {
    setbackgroundImageStyle({
      // backgroundImage: `url(http://localhost:3000/static/media/Video-Banner.a4cbe1a896af595f64a3.svg`,
      backgroundImage: `url(${getEnvUrl()}/quiz/qusImage/${
        question.id
      }?width=1200)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      height: "100vh",
      position: "relative",
      backgroundSize: "contain",
    });
  }, [question.id]);
  return (
    <>
      <div className="position-relative">
        
        <div style={backgroundImageStyle}>
          <Image
            onClick={quitQuiz}
            src="/svgs/close.svg"
            alt="CloseIcon"
            role="button"
            className={classes.closeIcon}
          />
          <Image
            onClick={() => {
              handleScreenSize();
            }}
            src="/svgs/Quiz/quiz-screen-size.svg"
            alt="Big Screen"
            // width={56}
            role="button"
            className="position-absolute bottom-0 end-0 m-3 pb-4"
          />
          <div className={classes.progressBarHead}>
            <ProgressBar
              percentage={Math.round((currentQNumber / totalQues) * 100)}
            />
          </div>
          <div className={classes.sideBg}>
            <div className={classes.sideBarRowHead}>
              {currentQNumber != 1 && (
                <Image
                  src="/svgs/arrow-back-level.svg"
                  role="button"
                  onClick={prevQuestion}
                />
              )}
              {/* <span className={classes.sideBarTitleHead}>Level 1 </span> */}
              &nbsp;
            </div>
            <div className={classes.sidebarQuiz}>
                        <Image src="/pngs/Logo.png"  className={classes.logo} />
              <div className={classes.sideBarQuestionTextHead}>
                <span className={classes.sideBarQuestionHead}>
                  Question {currentQNumber}/{totalQues}
                </span>
                <span className={classes.sideBarQuestion}>
                  {question?.question}
                </span>
                <div className={classes.sideBarAnswers}>
                  {answers.map((answer, index) => (
                    <div
                      key={index}
                      className={`${classes.sideBarAnswer} ${
                        selectedAnswer === answer ? classes.sideBarSelected : ""
                      }`}
                      onClick={() => handleAnswerClick(answer, index)}
                    >
                      <div className={classes.sideBarLetterCircle}>
                        {String.fromCharCode(65 + index)} {/* A, B, C, D */}
                      </div>
                      <span>{answer}</span>
                    </div>
                  ))}
                </div>
                <div className={`${classes.sideBarLastRowHead}`}>
                  {currentQNumber != totalQues && (
                    <Image
                      onClick={() => {
                        nextQuestion();
                      }}
                      src="/svgs/Quiz/quiz-right-arrow.svg"
                      role="button"
                      className={classes.sideBarRightArrowHead}
                    />
                  )}

                  {currentQNumber == totalQues && (
                    <Button onClick={quizSubmit}>Submit</Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isScreenBig && (
          <div className={classes.overlayScreen}>
            <Image
              src={`${getEnvUrl()}/quiz/qusImage/${question.id}`}
              alt="FullImg"
              className={classes.overlayFullImg}
            />
            <div
              className="position-absolute"
              style={{ bottom: "7%", left: "80%" }}
            >
              <Image
                onClick={() => {
                  setIsScreenBig(false);
                }}
                src="/svgs/Quiz/quiz-screen-size.svg"
                alt="Big Screen"
                role="button"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Tempalte;
