import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "../../styles/HomePage/homepage.module.css";

const MessageCard = () => {
  return (
    <div className={styles.containerHead2}>
      <Container fluid className={styles.containerHead}>
        <Row className={styles.messageRowHead}>
          <Col xs={12} md={10} className={styles.messageHead}>
            <span className={styles.messageOne}>Connect with our AI!</span>
            <span className={styles.messageLine}>|</span>
            <span className={styles.messageTwo}>
              Give your questions will get the correct answers
            </span>
          </Col>
          <Col
            xs={12}
            md={2}
            className="d-flex"
          >
            <Image
              src="/pngs/AI-Icon-Latest.png"
              height={"100%"}
              className={styles.aILogo}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MessageCard;
