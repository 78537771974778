import React from "react";
import classes from "../../styles/Footer/contentList.module.css";
import { Card, Col, Container, Image, Row } from "react-bootstrap";

const AboutUs = () => {
  return (
    <div className={classes.aboutUsMain}>
      <Container fluid className="px-5">
        <div>
          <h2 className={classes.aboutUsHeading}>About Us</h2>
          <Image src="/svgs/about-us.svg" width={"100%"} className="mb-4" />
          <div className="d-flex flex-column gap-4">
            <span className={classes.aboutUsPara}>
              My Talking Tree is an AI-powered ‘Smart Teacher’ designed to teach
              kids the way they imagine learning.  It offers an amazingly
              engaging, versatile and scalable progressive K-5 STEM learning
              system with which it has captured the imagination of kids, parents
              and educators alike! Mr. Dudu delivers STEM learning with powerful
              technology and innovative features that make learning easy and
              truly fun. It keeps kids engaged and eager to learn.​
            </span>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3 className={classes.aboutUsHeading1}>
                “ My Talking Tree Your Friend from Nature……​ “
              </h3>
              <span className={classes.aboutUsPara1}>
                Imagined, Designed and made in India.
              </span>
            </div>
            <span className={classes.aboutUsPara2}>
              My Talking Tree’s curriculum includes.​
            </span>
          </div>
        </div>
      </Container>
      <Container fluid className="px-4">
        {/* <div className="d-flex gap-4 flex-column"> */}
        <Row>
          <Col sm={6} md={4} className="mb-4">
            <div className={classes.aboutUsImgHead}>
              <Image src="/pngs/About-Video-Latest.png" width={"100%"} />
              <h3
                className={`${classes.aboutUsHeading2} ${classes.hoverHeading}`}
              >
                Videos
              </h3>
              <div className={classes.hoverCardContent}>
                <h3 className={classes.hoverTitle}>Videos</h3>
                <hr className={classes.hoverHorizontal} />
                <span className={classes.hoverPara}>
                  Interactive educational videos for English, Environmental
                  Sciences (EVS), Social and Emotional Learning, General
                  Knowledge  and Mathematics subjects.
                </span>
              </div>
            </div>
          </Col>
          <Col sm={6} md={4} className="mb-4">
            <div className={classes.aboutUsImgHead}>
              <Image src="/pngs/About-Flashcards-Latest.png" width={"100%"} />
              <h3
                className={`${classes.aboutUsHeading2} ${classes.hoverHeading}`}
              >
                Flashcards
              </h3>
              <div className={classes.hoverCardContent}>
                <h3 className={classes.hoverTitle}>Flashcards</h3>
                <hr className={classes.hoverHorizontal} />
                <span className={classes.hoverPara}>
                  Interactive teaching aids to visually demonstrate key terms
                  from the educational videos using pictorial representations.​
                </span>
              </div>
            </div>
          </Col>
          <Col sm={6} md={4} className="mb-4">
            <div className={classes.aboutUsImgHead}>
              <Image src="/pngs/About-Test-Latest.png" width={"100%"} />
              <h3
                className={`${classes.aboutUsHeading2} ${classes.hoverHeading}`}
              >
                Test
              </h3>
              <div className={classes.hoverCardContent}>
                <h3 className={classes.hoverTitle}>Test</h3>
                <hr className={classes.hoverHorizontal} />
                <span className={classes.hoverPara}>
                  Examine the identification skills of a child in two ways; one
                  is picture identification, and the other is word
                  identification.​
                </span>
              </div>
            </div>
          </Col>

          <Col sm={6} md={4} className="mb-4">
            <div className={classes.aboutUsImgHead}>
              <Image src="/pngs/About-Spellings-Latest.png" width={"100%"} />
              <h3
                className={`${classes.aboutUsHeading2} ${classes.hoverHeading}`}
              >
                Spellings
              </h3>
              <div className={classes.hoverCardContent}>
                <h3 className={classes.hoverTitle}>Spellings</h3>
                <hr className={classes.hoverHorizontal} />
                <span className={classes.hoverPara}>
                  Designed to test the spelling skill of the child. A choice can
                  be made between easy mode and a hard mode.​
                </span>
              </div>
            </div>
          </Col>
          <Col sm={6} md={4} className="mb-4">
            <div className={classes.aboutUsImgHead}>
              <Image src="/pngs/About-Rhymes-Latest.png" width={"100%"} />
              <h3
                className={`${classes.aboutUsHeading2} ${classes.hoverHeading}`}
              >
                Rhymes
              </h3>
              <div className={classes.hoverCardContent}>
                <h3 className={classes.hoverTitle}>Rhymes</h3>
                <hr className={classes.hoverHorizontal} />
                <span className={classes.hoverPara}>
                  Traditional to trendy animated rhymes that leaves
                  an everlasting dynamic audio-visual experience.​
                </span>
              </div>
            </div>
          </Col>
          <Col sm={6} md={4} className="mb-4">
            <div className={classes.aboutUsImgHead}>
              <Image
                src="/pngs/About-Quiz-Latest.png"
                width={"100%"}
                className={classes.lastImg}
              />
              <h3
                className={`${classes.aboutUsHeading2} ${classes.hoverHeading}`}
              >
                Quiz
              </h3>
              <div className={classes.hoverCardContent}>
                <h3 className={classes.hoverTitle}>Quiz</h3>
                <hr className={classes.hoverHorizontal} />
                <span className={classes.hoverPara}>
                  A predefined bunch of quizzes to test the subject
                  and general knowledge.​
                </span>
              </div>
            </div>
          </Col>
        </Row>
        {/* </div> */}
        <div className={classes.aboutUsAiHead}>
          <Row>
            <Col sm={6} className="d-flex justify-content-center">
              <Image
                src="/pngs/Ai-Isolation-Mode-latest.png"
                className={classes.aiImgBg}
              />
            </Col>
            <Col
              sm={6}
              className="d-flex justify-content-center align-items-center text-center"
            >
              <div className={classes.aboutUsAiBg}>
                <h3 className={classes.aboutUsAiHead1}>
                  Artificial Intelligence
                </h3>
                <hr className={classes.horizontalLineAi} />
                <span className={classes.aboutUsAiPara}>
                  A special interface that allows the users to interact with the
                  tree. The tree takes your question as input and replies you
                  with a feasible answer.
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="d-flex gap-3 flex-column">
          <span className={classes.aboutUsPara3}>
            My Talking Tree community is constantly buzzing with the excitement
            of endless collaboration, proving that learning is more fun and more
            effective when we put our heads together.​
          </span>
          <div className={classes.aboutUsPara4}>
            <Image src="/pngs/captive_portal.png" />
            <span>www.mytalkingtree.com​</span>
            <span className={classes.verticalLineAboutUs}></span>
            <Image src="/pngs/perm_phone_msg.png" />
            <span>+91 72004 72006</span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
