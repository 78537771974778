import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import classes from "../../styles/profile.module.css";
import { getLoginDevices, signOutDevice } from "../../services/userService"; 
import 'bootstrap-icons/font/bootstrap-icons.css';

const LoginDevices = () => {
  const [devices, setDevices] = useState([]);

  const fetchDevices = async () => {
    try {
      const res = await getLoginDevices();
      if (res.status) {
        setDevices(res.data);
      } else {
        console.error(res.errorMessage);
      }
    } catch (error) {
      console.error('Failed to fetch devices:', error);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  const handleSignOut = async (deviceId) => {
    try {
      const res = await signOutDevice(deviceId);
      if (res.status) {
        fetchDevices(); 
      } else {
        console.error(res.errorMessage);
      }
    } catch (error) {
      console.error('Failed to sign out of device:', error);
    }
  };
  return (
    <div className={classes.loginDevices}>
      <h3 className={classes.deviceHeading}>Logged-in Devices</h3>
      <Form>
        <Row>
          {devices.length ? (
            devices.map((device) => (
              <Col md={12} key={device.deviceid} className={classes.deviceRow}>
                <div className={classes.deviceInfo}>
                  <p><strong>Device :</strong> {device.device_name}</p>
                  <p><strong>Last Active :</strong> {new Date(device.last_login_time).toLocaleString()}</p>
                </div>
                <div className={classes.signOutButtonHead}>
                  {device.isCurrent ? (
                    <button className={classes.disabledButton} disabled>
                      Current Device
                    </button>
                  ) : (
                    <button
                     type="button"
                      onClick={() => handleSignOut(device.deviceid)}
                    >
                      Sign Out
                    </button>
                  )}
                </div>
              </Col>
            ))
          ) : (
            <Col md={12}>
              <p>No devices found.</p>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default LoginDevices;
