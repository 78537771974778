import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import ErrorComponent from "./components/errorComponent/errorComponent";
import MainView from "./mainview";
import BodyView from "./bodyview";
import VideoDetailView from "./pages/VideoPage/videoDetailView";
import HomePage from "./pages/HomePage/homepage";
import Search from "./pages/SearchPage/search";
import ProfilePage from "./pages/UserProfilePage/profile";
import FlashCards from "./pages/FlashCards/flashCards";
import SmartLearn from "./pages/SmartLearnPage/smartLearn";
import PlayVideo from "./pages/VideoPage/playVideo";
import Subjects from "./pages/SubjectsPage/subjects";
import ContentList from "./pages/FooterContent/contentList";
import QuizDetailView from "./pages/QuizScreenLevels/quizDetailView";
import FlashCardsDetailView from "./pages/FlashCards/flashCardsDetailView.";
import QuizScreen from "./pages/QuizScreenLevels/quizPlay";
import Login from "./pages/LoginPage/login";
import { isUserLogin } from "./utils/Api";
import Troubleshoot from "./pages/FooterContent/troubleshoot";
import HelpAndSupport from "./pages/FooterContent/helpAndSupport";
import AboutUs from "./pages/FooterContent/aboutUs";
import PlayFlashCard from "./pages/FlashCards/playFlashCard";
import AiScreen from "./pages/AiScreens/aiScreen";
import ShowSkeleton from "./pages/Skeleton/showSkeletion"
const ProtectedRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = isUserLogin(); // your logic to check if the user is authenticated
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};
const AfterLoginRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = isUserLogin(); // your logic to check if the user is authenticated
  return !isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
};

const router = createBrowserRouter([
  {
    path: "/ss",
    element: <ShowSkeleton />,
  },
  {
    path: "/impersonateLogin",
    element: <Login />,
  },
  {
    path: "/login",
    element: <AfterLoginRoute element={Login} />,
  }, 
  {
    element: <ProtectedRoute element={BodyView} />,
    children: [
      {
        path: "/video/:id",
        element: <PlayVideo />,
      },
      {
        path: "/quizPlay/:quiz_id",
        element: <QuizScreen />,
      },
      {
        path: "/flashcard/:fc_id",
        element: <PlayFlashCard />,
      },
      {
        path: "/ai",
        element: <AiScreen />,
      }
    ],
  },
  {
    element: <ProtectedRoute element={MainView} />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/home",
        element: <HomePage />,
      },
      {
        path: "/videoDetailView/:id",
        element: <VideoDetailView />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
      {
        path: "/smart-learn",
        element: <SmartLearn />,
      },
      {
        path: "/flash-cards",
        element: <FlashCards />,
      },
      {
        path: "/subjects",
        element: <Subjects />,
      },
      {
        path: "/subjects/:id",
        element: <Subjects />,
      },
      {
        path: "/content-list/:id",
        element: <ContentList />,
      },
      {
        path: "/troubleshoot",
        element: <Troubleshoot />,
      },
      {
        path: "/helpandsupport",
        element: <HelpAndSupport />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/quizDetailView/:id",
        element: <QuizDetailView />,
      },
      {
        path: "/flashCardDetailView/:id",
        element: <FlashCardsDetailView />,
      },
    ],
    errorElement: <ErrorComponent />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
