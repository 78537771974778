import React, { useState, useEffect } from "react";
import { Image, Button } from "react-bootstrap";
import styles from "../../styles/login.module.css";
import LoginForm from "./loginForm";
import classes from "../../styles/HomePage/homepage.module.css";
import Footer from "../../components/footer";
import { useLocation, useNavigate } from 'react-router-dom';

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const username = searchParams.get('username');
  useEffect(() => {
    if (token) {
      localStorage.clear();
      localStorage.setItem("token",token)
      localStorage.setItem("name",username) 
      navigate("/profile");
    }
    const body = document.querySelector("body");
    body.style.overflow = isLoggedIn ? "hidden" : "auto";
    // Clean up effect: Restore body overflow on component unmount
    return () => {
      body.style.overflow = "auto";
    };
  }, [isLoggedIn]);
  const handleLogin = () => {
    setIsLoggedIn(true);
  };
  const handleClose = () => {
    setIsLoggedIn(false);
  };
  return (
    <>
   <div className={classes.notLogin}> 
   <div className={styles.loginImgHead}>
        <Image src="/svgs/Mtt-logo.svg" className={styles.loginLogo} />
        <div className={styles.textHead}>
          <h5 className={styles.loginTitleHead}>Login to My Talking Tree </h5>
          <span className={styles.loginPara}>
            Start learning from watching the video and play the quiz{" "}
          </span>
          <Button className={styles.loginButton} onClick={handleLogin}>
            Log In
          </Button>
        </div>
      </div>
      <Footer />
    </div>
      
      {isLoggedIn && (
        <LoginForm isLoggedIn={isLoggedIn} handleClose={handleClose} />
      )}
    </>
  );
};

export default Login;
