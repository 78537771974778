import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classes from "../../styles/smartLearn.module.css";
import { FastAverageColor } from "fast-average-color";
import { Container, Image } from "react-bootstrap";
import SmartLearnCarouselCard from "./smartLearnCarouselCard";
import { useNavigate } from "react-router-dom";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 6 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
};
const SmartLearnCarouselView = ({
  title,
  data,
  type,
  routeName,
  routeType,
  id,
  colorIndex,
}) => {
  const navigate = useNavigate();
  const [colors, setColors] = useState({});
  const colorsCodes = [
    "#ff8125",
    "#ff2581",
    "#4425ff",
    "#ff3f25",
    "#ff1066",
    "#7910ff",
    "#ff7410",
    "#1053ff",
    "#cb8600",
    "#ec11ff",
  ];

  useEffect(() => {
    const fac = new FastAverageColor();
    const fetchColors = async () => {
      const newColors = {};
      for (let item of data) {
        try {
          const color = await fac.getColorAsync(item.img);
          newColors[item.id] = color.rgba;
        } catch (error) {
          newColors[item.id] = "rgba(0, 0, 0, 0.5)";
        }
      }
      setColors(newColors);
    };

    if (data?.length > 0) {
      fetchColors();
    }

    return () => {
      fac.destroy();
    };
  }, [data]);
  const handleClick = () => {
    navigate("/flash-cards", {
      state: { message: id },
    });
  };

  const handleSubjectsClick = () => {
    navigate("/flash-cards", {
      state: { message: id, routeType: "Subjects" },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const hash = window.location.hash;
      if (hash) {
        try {
          // Remove the hash symbol (#) and properly escape the ID
          const id = hash.substring(1);
          const element = document.getElementById(id);
          if (element) {
            const yOffset = -150; // Offset of 200px from the top
            const y =
              element.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
            window.scrollTo({ top: y, behavior: "smooth" });
          } else {
            console.warn(`Element with ID ${hash} not found.`);
          }
        } catch (e) {
          console.error(`Invalid selector: ${hash}`, e);
        }
      }
    };
    handleScroll(); // Call the function once when the component mounts
    window.addEventListener("hashchange", handleScroll); // Listen for hash changes
    return () => {
      window.removeEventListener("hashchange", handleScroll); // Cleanup the event listener
    };
  }, []);

  const titleColor = (title) => {
    const colorClasses = {
      "Flash Cards": classes.cardTitleOne,
      Spellings: classes.cardTitleTwo,
      Test: classes.cardTitleThree,
      Quiz: classes.cardTitleFour,
    };
    return colorClasses[title];
  };

  const subjectsTitleColor = (title) => {
    const colorClasses = {
      English: classes.subjectTitleOne,
      Videos: classes.subjectTitleOne,
      EVS: classes.subjectTitleTwo,
      Quiz: classes.subjectTitleTwo,
      Mathematics: classes.subjectTitleThree,
      "Flash Card": classes.subjectTitleThree,
      Rhymes: classes.subjectTitleFour,
      "GK & SEL": classes.subjectTitleFive,
      Stories: classes.subjectTitleSix,
    };
    
    if(colorClasses[title]==undefined){
      const cls_array=[classes.subjectTitleOne,
        classes.subjectTitleTwo,
        classes.subjectTitleThree,
        classes.subjectTitleFour]
      const randomIndex = Math.floor(Math.random() * 3);
      return cls_array[randomIndex]
    }
    return colorClasses[title];
  };

  return (
    <div className={classes.carouselLines} id={id + "s"}>
      <Container fluid className={classes.carouselContainer}>
        <div
          className={
            type === "noCarousel"
              ? classes.noCarouselHead
              : classes.carouselHead
          }
        >
          <>
            <div
              className={
                type === "noCarousel"
                  ? classes.noCarouselSubHead
                  : classes.cardTextHead
              }
            >
              <span
                className={
                  routeName === "subjects" || routeType === "Subjects"
                  ? subjectsTitleColor(title)
                  : titleColor(title)
                }
                // style={{ backgroundColor: colorsCodes[colorIndex] }}
              >
                {title}
              </span>
              <span
                className={classes.cardClick}
                onClick={
                  routeName === "subjects" ? handleSubjectsClick : handleClick
                }
              >{routeName=="flash-cards"?(""):("See all")}
              </span>
            </div>
            {type !== "noCarousel" ? (
              <Carousel
                responsive={responsive}
                containerClass="corousal-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                slidesToSlide={2}
              >
                {data?.map((item) => (
                  <SmartLearnCarouselCard
                    key={item.id}
                    item={item}
                    averageColor={colors[item.id]}
                    headingTitle={title}
                  />
                ))}
              </Carousel>
            ) : (
              data?.map((item) => (
                <SmartLearnCarouselCard
                  key={item.id}
                  item={item}
                  averageColor={colors[item.id]}
                  headingTitle={title}
                  type={type}
                />
              ))
            )}
          </>
        </div>
      </Container>
    </div>
  );
};

export default SmartLearnCarouselView;
