export const data = [
  {
    id: 1,
    name: "Dragon Cards",
    img: "/pngs/video-thumbline-1.png",
    title: "Flash Cards",
  },
  {
    id: 2,
    name: "Jumping Jack",
    img: "/pngs/video-thumbline.png",
    title: "Quiz",
  },
  {
    id: 3,
    name: "Vechile Matching",
    img: "/svgs/img3.svg",
    title: "Quiz",
  },
  {
    id: 4,
    name: "The Whack Mole",
    img: "/svgs/img4.svg",
    title: "Quiz",
  },
  {
    id: 5,
    name: "Dragon Cards",
    img: "/svgs/img1.svg",
    title: "Flash Cards",
  },

  {
    id: 6,
    name: "Jumping Jack",
    img: "/svgs/img2.svg",
    title: "Quiz",
  },
  {
    id: 7,
    name: "Vechile Matching",
    img: "/svgs/img3.svg",
    title: "Quiz",
  },
  {
    id: 8,
    name: "The Whack Mole",
    img: "/svgs/img4.svg",
    title: "Quiz",
  },
];

export const data1 = [
  {
    id: 1,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 2,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
  {
    id: 3,
    name: "The Vegetables",
    img: "/svgs/vegetables.svg",
  },
  {
    id: 4,
    name: "The Vehicles",
    img: "/svgs/vehicles.svg",
  },
  {
    id: 5,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 6,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
];

export const gradesData = [
  {
    id: 1,
    img: "/pngs/kg1.png",
  },
  {
    id: 2,
    img: "/pngs/kg2.png",
  },
  {
    id: 3,
    img: "/pngs/grade1.png",
  },
  {
    id: 4,
    img: "/pngs/grade2.png",
  },
  {
    id: 5,
    img: "/pngs/grade3.png",
  },
  {
    id: 6,
    img: "/pngs/kg1.png",
  },
];

export const SubjectsData = [
  {
    id: 1,
    img: "/pngs/Eng-Sub.png",
    hoverImg: "/svgs/Eng-Sub-Hover.svg",
  },
  {
    id: 2,
    img: "/pngs/EVS-Sub.png",
    hoverImg: "/svgs/Evs-Sub-Hover.svg",
  },
  {
    id: 3,
    img: "/pngs/Math-Sub.png",
    hoverImg: "/svgs/Math-Sub-Hover.svg",
  },
  {
    id: 4,
    img: "/pngs/Rhym-Sub.png",
    hoverImg: "/svgs/Rhyme-Sub-Hover.svg",
  },
  {
    id: 5,
    img: "/pngs/Gl&St-Sub.png",
    hoverImg: "/svgs/Gl&St-Sub-Hover.svg",
  },
  {
    id: 6,
    img: "/pngs/Stories-Sub.png",
    hoverImg: "/svgs/Stories-Sub-Hover.svg",
  },
];

export const subjectsEvs = [
  {
    id: 1,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
  {
    id: 2,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 3,
    name: "The Vehicles",
    img: "/svgs/vehicles.svg",
  },
  {
    id: 4,
    name: "The Vegetables",
    img: "/svgs/vegetables.svg",
  },
  {
    id: 5,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 6,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
];

export const subjectsMaths = [
  {
    id: 1,
    name: "The Vehicles",
    img: "/svgs/vehicles.svg",
  },
  {
    id: 2,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
  {
    id: 3,
    name: "The Vegetables",
    img: "/svgs/vegetables.svg",
  },
  {
    id: 4,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 5,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
  {
    id: 6,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
];

export const popularData = [
  {
    id: 1,
    name: "Jumping Jack",
    img: "/svgs/img2.svg",
    title: "Quiz",
  },
  {
    id: 2,
    name: "The Whack Mole",
    img: "/svgs/img4.svg",
    title: "Quiz",
  },
  {
    id: 3,
    name: "Dragon Cards",
    img: "/svgs/img1.svg",
    title: "Flash Cards",
  },
  {
    id: 4,
    name: "Vechile Matching",
    img: "/svgs/img3.svg",
    title: "Quiz",
  },
  {
    id: 5,
    name: "Jumping Jack",
    img: "/svgs/img2.svg",
    title: "Quiz",
  },

  {
    id: 6,
    name: "Dragon Cards",
    img: "/svgs/img1.svg",
    title: "Flash Cards",
  },
];

export const subjectsRhymes = [
  {
    id: 1,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
  {
    id: 2,
    name: "The Vegetables",
    img: "/svgs/vegetables.svg",
  },
  {
    id: 3,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 4,
    name: "The Vehicles",
    img: "/svgs/vehicles.svg",
  },
  {
    id: 5,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 6,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
];

export const subjectsGk = [
  {
    id: 1,
    name: "The Vegetables",
    img: "/svgs/vegetables.svg",
  },
  {
    id: 2,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 3,
    name: "The Vehicles",
    img: "/svgs/vehicles.svg",
  },
  {
    id: 4,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
  {
    id: 5,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 6,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
];

export const audioData = [
  {
    id: 1,
    name: "The Vehicles",
    img: "/svgs/Audio-C1.svg",
    // hoverImg: "/svgs/playaudio.svg",
  },
  {
    id: 2,
    name: "The Vegetables",
    img: "/svgs/Audio-C2.svg",
    // hoverImg: "/svgs/vegetables-hover.svg",
  },
  {
    id: 3,
    name: "Musical Instruments",
    img: "/svgs/Audio-C3.svg",
    // hoverImg: "/svgs/musical-hover.svg",
  },
  {
    id: 4,
    name: "Types of House",
    img: "/svgs/Audio-C4.svg",
    // hoverImg: "/svgs/houses-hover.svg",
  },
  {
    id: 5,
    name: "The Vehicles",
    img: "/svgs/Audio-C1.svg",
    // hoverImg: "/svgs/vehicles-hover.svg",
  },
  {
    id: 6,
    name: "The Vegetables",
    img: "/svgs/Audio-C2.svg",
    // hoverImg: "/svgs/vegetables-hover.svg",
  },
];
export const trendingVideoList = [
  {
    id: 1,
    name: "Dragon Cards",
    img: "/svgs/img1.svg",
    title: "Flash Cards",
  },
  {
    id: 2,
    name: "Jumping Jack",
    img: "/svgs/img2.svg",
    title: "Quiz",
  },
  {
    id: 3,
    name: "Vechile Matching",
    img: "/svgs/img3.svg",
    title: "Quiz",
  },
  {
    id: 4,
    name: "The Whack Mole",
    img: "/svgs/img4.svg",
    title: "Quiz",
  },
  {
    id: 5,
    name: "Types of House",
    img: "/svgs/houses.svg",
  },
  {
    id: 6,
    name: "Musical Instruments",
    img: "/svgs/musical.svg",
  },
  {
    id: 7,
    name: "The Vegetables",
    img: "/svgs/vegetables.svg",
  },
  {
    id: 8,
    name: "The Vehicles",
    img: "/svgs/vehicles.svg",
  },
];
export const cardData = [
  {
    title: "Flash Cards",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    imgSrc: "/svgs/FlashCard.svg",
  },
  {
    title: "Spellings",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    imgSrc: "/svgs/Spellings.svg",
  },
  {
    title: "Quiz",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    imgSrc: "/svgs/Quiz.svg",
  },
  {
    title: "Test",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    imgSrc: "/svgs/Test.svg",
  },
];

export const videoCardData = [
  {
    title: "Flash Cards",
    imgSrc: "/svgs/video-FlashCards.svg",
  },
  {
    // title: "Spellings",
    // imgSrc: "/svgs/video-Spellings.svg",
  },
  {
    title: "Quiz",
    imgSrc: "/svgs/video-Quiz.svg",
  },
  // {
  //   title: "Test",
  //   imgSrc: "/svgs/video-Test.svg",
  // },
];

export const gradeFilters = [
  {
    id: 1,
    img: "/pngs/kgFilter-1.png",
  },
  {
    id: 2,
    img: "/pngs/kgFilter-2.png",
  },
  {
    id: 3,
    img: "/pngs/gradeFilter-1.png",
  },
  {
    id: 4,
    img: "/pngs/gradeFilter-2.png",
  },
  {
    id: 5,
    img: "/pngs/gradeFilter-4.png",
  },
];

export const gradesLatestImg = [
  {
    id: 1,
    name: "KG1",
    img: "/pngs/Grades/kg1-new.png",
  },
  {
    id: 2,
    name: "KG2",
    img: "/pngs/Grades/kg2-new.png",
  },
  {
    id: 3,
    name: "Grade 1",
    img: "/pngs/Grades/grade1-new.png",
  },
  {
    id: 4,
    name: "Grade 2",
    img: "/pngs/Grades/grade2-new.png",
  },
  {
    id: 5,
    name: "Grade 3",
    img: "/pngs/Grades/grade4-new.png",
  },
];

export const flashData = [
  {
    id: 1,
    name: "Dragon Cards",
    img: "/svgs/img1.svg",
    title: "Flash Cards",
  },
  {
    id: 2,
    name: "Vegetables",
    img: "/svgs/vegetable-flash.svg",
    title: "Flash Cards",
  },
  {
    id: 3,
    name: "Musical Instruments",
    img: "/svgs/musical-flash.svg",
    title: "Flash Cards",
  },
  {
    id: 4,
    name: "Flowers",
    img: "/svgs/flowers-flash.svg",
    title: "Flash Cards",
  },
  {
    id: 5,
    name: "Dragon Cards",
    img: "/svgs/img1.svg",
    title: "Flash Cards",
  },
];

export const spellingsData = [
  {
    id: 1,
    name: "Fruits",
    img: "/svgs/fruits-spelling.svg",
    title: "Spellings",
  },
  {
    id: 2,
    name: "Birds",
    img: "/svgs/birds-spell.svg",
    title: "Spellings",
  },
  {
    id: 3,
    name: "Vehicles",
    img: "/svgs/vehical-spell.svg",
    title: "Spellings",
  },
  {
    id: 4,
    name: "Insects",
    img: "/svgs/insects-spell.svg",
    title: "Spellings",
  },
  {
    id: 5,
    name: "Fruits",
    img: "/svgs/fruits-spelling.svg",
    title: "Spellings",
  },
];

export const testData = [
  {
    id: 1,
    name: "Vehicles",
    img: "/svgs/vehical-spell.svg",
    title: "Test",
  },
  {
    id: 2,
    name: "Insects",
    img: "/svgs/insects-spell.svg",
    title: "Test",
  },
  {
    id: 3,
    name: "Birds",
    img: "/svgs/birds-spell.svg",
    title: "Test",
  },
  {
    id: 4,
    name: "Fruits",
    img: "/svgs/fruits-spelling.svg",
    title: "Test",
  },
  {
    id: 5,
    name: "Vehicles",
    img: "/svgs/vehical-spell.svg",
    title: "Test",
  },
];

export const quizData = [
  {
    id: 1,
    img: "/svgs/quiz-level1.svg",
  },
  {
    id: 2,
    img: "/svgs/quiz-level2.svg",
  },
  {
    id: 3,
    img: "/svgs/quiz-level3.svg",
  },
  {
    id: 4,
    img: "/svgs/quiz-level4.svg",
  },
  {
    id: 5,
    img: "/svgs/quiz-level1.svg",
  },
];

export const colors = [
  "#FFB6C1", // Light Pink
  "#ADD8E6", // Light Blue
  "#90EE90", // Light Green
  "#FFD700", // Gold
  "#FF69B4", // Hot Pink
  "#8A2BE2", // Blue Violet
  "#7FFF00", // Chartreuse
  "#DC143C", // Crimson
];

export const homeBannerImgs = [
  {
    id: 1,
    imgSrc: "/svgs/HomeBanner.svg",
  },
  {
    id: 2,
    imgSrc: "/svgs/HomeBanner.svg",
  },
  {
    id: 3,
    imgSrc: "/svgs/HomeBanner.svg",
  },
  {
    id: 4,
    imgSrc: "/svgs/HomeBanner.svg",
  },
];

export const contentDummyData = [
  {
    title: "KG1",
    data: [
      {
        title: "English",
        data: [
          {
            id: 1,
            linkTitles: "Learn letters A to G",
          },
          {
            id: 2,
            linkTitles: "Learn letters G to N",
          },
          {
            id: 3,
            linkTitles: "Learn letters O to T",
          },
          {
            id: 4,
            linkTitles: "Learn Letters U to Z",
          },
          {
            id: 5,
            linkTitles: "Learn Alphabet",
          },
          {
            id: 6,
            linkTitles: "Phonic Sounds",
          },
          {
            id: 7,
            linkTitles: "Pre-Writing Strokes",
          },
        ],
      },
      {
        title: "GK & SEL",
        data: [
          {
            id: 1,
            linkTitles: "Traffic Rules",
          },
          {
            id: 2,
            linkTitles: "Know Your Address",
          },
          {
            id: 3,
            linkTitles: "Good Touch And Bad Touch",
          },
          {
            id: 4,
            linkTitles: "Fire Safety",
          },
        ],
      },
      {
        title: "Mathematics",
        data: [
          {
            id: 1,
            linkTitles: "More or less",
          },
          {
            id: 2,
            linkTitles: "Numbers 1 to 10",
          },
          {
            id: 3,
            linkTitles: "Numbers 11 to 20",
          },
          {
            id: 4,
            linkTitles: "Numbers 21 to 30",
          },
          {
            id: 5,
            linkTitles: "Numbers 31 to 50",
          },
          {
            id: 6,
            linkTitles: "Numbers 51 to 100",
          },
          {
            id: 7,
            linkTitles: "Shapes",
          },
          {
            id: 8,
            linkTitles: "Colours and shapes",
          },
        ],
      },
      {
        title: "Rhymes",
        data: [
          {
            id: 1,
            linkTitles: "ABC Song",
          },
          {
            id: 2,
            linkTitles: "Aeroplane",
          },
          {
            id: 3,
            linkTitles: "Animal Songs",
          },
          {
            id: 4,
            linkTitles: "Black Sheep",
          },
          {
            id: 5,
            linkTitles: "Bits of Paper",
          },
          {
            id: 6,
            linkTitles: "Body Parts",
          },
          {
            id: 7,
            linkTitles: "Christmas Time",
          },
          {
            id: 8,
            linkTitles: "Chubby Cheeks",
          },
        ],
      },
      {
        title: "Video Stories",
        data: [
          {
            id: 1,
            linkTitles: "The Curious frog",
          },
          {
            id: 2,
            linkTitles: "The Cap Seller and the Monkey",
          },
          {
            id: 3,
            linkTitles: "The Proud Parrot",
          },
          {
            id: 4,
            linkTitles: "The Elephant And The Ant",
          },
          {
            id: 5,
            linkTitles: "The Crocodile And The Monkey",
          },
          {
            id: 6,
            linkTitles: "Lazy John",
          },
          {
            id: 7,
            linkTitles: "The Two Cats And A Monkey ",
          },
          {
            id: 8,
            linkTitles: "The Peacock And The Crow",
          },
        ],
      },
      {
        title: "Audio stories",
        data: [
          {
            id: 1,
            linkTitles: "The Dog And The Goat (Audio)",
          },
          {
            id: 2,
            linkTitles: "The Girl and The Ice Cream Truck (Audio)",
          },
          {
            id: 3,
            linkTitles: "The Bear and the Bee (Audio)",
          },
          {
            id: 4,
            linkTitles: "The Fox And The Sour Grapes",
          },
          {
            id: 5,
            linkTitles: "The Kind Giant Audio story",
          },
          {
            id: 6,
            linkTitles: "The Fox And The Sour Grapes Audio Story",
          },
          {
            id: 7,
            linkTitles: "The Proud Parrot Audio Story",
          },
        ],
      },
      {
        title: "Flash cards",
        data: [
          {
            id: 1,
            linkTitles: "Alphabet",
          },
        ],
      },
    ],
  },
  {
    title: "KG2",
    data: [
      {
        title: "English",
        data: [
          {
            id: 1,
            linkTitles: "Learn letters A to G",
          },
          {
            id: 2,
            linkTitles: "Learn letters G to N",
          },
          {
            id: 3,
            linkTitles: "Learn letters O to T",
          },
          {
            id: 4,
            linkTitles: "Learn Letters U to Z",
          },
          {
            id: 5,
            linkTitles: "Learn Alphabet",
          },
          {
            id: 6,
            linkTitles: "Phonic Sounds",
          },
          {
            id: 7,
            linkTitles: "Pre-Writing Strokes",
          },
        ],
      },
      {
        title: "GK & SEL",
        data: [
          {
            id: 1,
            linkTitles: "Traffic Rules",
          },
          {
            id: 2,
            linkTitles: "Know Your Address",
          },
          {
            id: 3,
            linkTitles: "Good Touch And Bad Touch",
          },
          {
            id: 4,
            linkTitles: "Fire Safety",
          },
        ],
      },
    ],
  },
];

export const troubleshootData = [
  {
    id: 1,
    question: "What is My Talking Tree? ",
    answer:
      "A. My Talking Tree is a robotic teacher assistant designed to make learning fun and engaging. Students can learn lessons in the form of interactive videos, providing a lovable and effective educational experience. ",
  },
  {
    id: 2,
    question: "How does My Talking Tree work? ",
    answer: "sadad",
  },
  {
    id: 3,
    question: "Is My Talking Tree suitable for all age groups? ",
    answer: "sadad",
  },
  {
    id: 4,
    question: "What subjects and topics does My Talking Tree cover? ",
    answer: "sadad",
  },
  {
    id: 5,
    question: "Is My Talking Tree customizable for different learning levels? ",
    answer: "sadad",
  },
  {
    id: 6,
    question:
      "Does My Talking Tree have other teaching resources apart from video lessons?  ",
    answer: "sadad",
  },
  {
    id: 7,
    question: "Is My Talking Tree safe for children? ",
    answer: "sadad",
  },
  {
    id: 8,
    question: "Can I use My Talking Tree offline? ",
    answer: "sadad",
  },
];
