import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classes from "../../styles/HomePage/homepage.module.css";
import CarouselCard from "./carouselData";
import { FastAverageColor } from "fast-average-color";
import { Container } from "react-bootstrap";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CarouselView = ({ title, data, type }) => {
  const [colors, setColors] = useState({});
  useEffect(() => {
    const fac = new FastAverageColor();
    const fetchColors = async () => {
      const newColors = {};
      for (let item of data) {
        try {
          const color = await fac.getColorAsync(item.img);
          newColors[item.id] = color.rgba;
        } catch (error) {
          console.error("Error fetching color:", error);
          newColors[item.id] = "rgba(0, 0, 0, 0.5)"; // default color if error occurs
        }
      }
      setColors(newColors);
    };

    if (data?.length > 0) {
      fetchColors();
    }

    return () => {
      fac.destroy();
    };
  }, []);

  return (
    <Container fluid className="pe-0 ps-5">
      <div className={classes.carouselHead}>
        <div className={classes.carouselSubHead}>
          <span className={`${classes.carouselTitle} ps-2`}>{title}</span>
          {/* <span className={classes.carouselClick}>{!type && "See all"}</span> */}
        </div>
        <Carousel
          responsive={responsive}
          containerClass="corousal-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          slidesToSlide={2}
        >
          {data?.map((item, i) => (
            <CarouselCard
              key={item.id}
              index={i}
              item={item}
              averageColor={colors[item.id]}
              headingTitle={title}
            />
          ))}
        </Carousel>
      </div>
    </Container>
  );
};

export default CarouselView;
