import React, { useState, useEffect } from "react";
import styles from "../../styles/login.module.css";
import { Container, Image, Row, Col } from "react-bootstrap";
import { UserLogin, resetPassword } from "../../services/userService";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ isLoggedIn, handleClose }) => {
  const navigate = useNavigate();
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [passwordResetMessage, setPasswordResetMessage] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const newErrors = {};
  const validateEmailOrMobile = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    return emailRegex.test(input) || mobileRegex.test(input);
  };

  useEffect(() => {
    setErrorMessage("");
  }, [emailOrMobile, password]);

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleChangeEmailOrMobile = (e) => {
    const input = e.target.value;
    setEmailOrMobile(input);
    if (errors.emailOrMobile && validateEmailOrMobile(input)) {
      setErrors((prevErrors) => ({ ...prevErrors, emailOrMobile: "" }));
    }
  };

  const handleChangePassword = (e) => {
    const input = e.target.value;
    setPassword(input);
    if (errors.password && validatePassword(input)) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const handleLogin = () => {
    if (!validateEmailOrMobile(emailOrMobile)) {
      newErrors.emailOrMobile = "Enter a valid email";
    }
    if (!validatePassword(password)) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      UserLogin(emailOrMobile, password)
        .then((result) => {
          if (result.status === true) {
            localStorage.setItem("token", result?.data?.token);
            localStorage.setItem("name", result?.data?.username);
            handleClose();
            navigate("/", {
              state: {
                loginMessage: result.message,
              },
            });
          } else {
            setErrorMessage(result.errorMessage);
          }
        })
        .catch((error) => {
          console.error("Login failed:", error);
        });
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleForgotPasswordClick = () => {
    setForgotPassword(true);
    setErrors({});
    setErrorMessage("");
  };

  const handleResetPassword = () => {
    if (!validateEmailOrMobile(emailOrMobile)) {
      newErrors.emailOrMobile = "Enter a valid email";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsDisabled(true);
      setIsLoading(true);
      resetPassword(emailOrMobile)
        .then((result) => {
          if (result.status === true) {
            setPasswordResetMessage(true);
            setTimeout(() => {
              setPasswordResetMessage(false);
              setForgotPassword(true);
              handleClose();
            }, 3000);
          } else {
            setIsLoading(false);
            setIsDisabled(false);
            // setErrorMessage("Invalid email or password");
            alert("Enter registered Email ID");
          }
        })
        .catch((error) => {
          console.error("Login failed:", error);
        });
    }
  };

  return (
    <div className={styles.modalOverlay}>
      {/* <div className={styles.modalContent}>
        <div className={styles.leftContent}>
          <div className={styles.leftContentSubHead}>
            <div className={styles.loginTextHead}>
              <div className={styles.firstRowHead}>
                <Image
                  src="/svgs/close.svg"
                  alt="CloseIcon"
                  className={styles.closeButton}
                  width={24}
                  onClick={handleClose}
                />
              </div>
              <div className={styles.formTextHead}>
                <h5 className={styles.formLogin}>{forgotPassword ? "Reset Password" : "Login"}</h5>
                <span className={styles.formpara}>
                  {forgotPassword ? "Enter Registered Email-Id" : "or Sign up to Continue"}
                </span>   
              </div>
            </div>
            <div className={styles.formInputHead}>
              <input
                type="text"
                placeholder="Email "
                autoComplete="nope"
                className={styles.inputField}
                value={emailOrMobile}
                onChange={handleChangeEmailOrMobile}
              />
              {errors.emailOrMobile && (
                <span className={styles.error}>{errors.emailOrMobile}</span>
              )}
              {!forgotPassword && (
                <>
                  <input
                    type={showPassword?'text':'password'}
                    placeholder="Password"
                    autoComplete="new-password"
                    className={styles.inputField}
                    value={password}
                    onChange={handleChangePassword}
                  />   
{errors.password && (
                    <span className={styles.error}>{errors.password}</span>
                  )}
                  {errors.password ? "" : (errorMessage && <div className={styles.error}>{errorMessage}</div>)}


            <div className={styles.showPasswordWrapper}> 
              <label htmlFor="showPassword" className={styles.forgotPassword}>
              <input
                type="checkbox"
                id="showPassword"
                checked={showPassword}
                onChange={toggleShowPassword}
                className={styles.checkbox}
              />  Show Password
              </label>

              {!forgotPassword && (
                <span className={styles.forgotPassword} onClick={handleForgotPasswordClick}>
                  Forget password?
                </span>
              )}
            </div> 
                  
                </>
              )}
             
              {passwordResetMessage && (
                <p className={styles.mathematic}>
                  A new password has been sent to your registered email.
                </p>
              )}
              <br></br>
              <button 
                className={styles.fullWidthButton} 
                onClick={forgotPassword ? handleResetPassword : handleLogin}
                disabled={isDisabled}
              >
                {forgotPassword ? "Reset Password" : "Log In"}
              </button>
              {!forgotPassword ? (
                <>
                  <div className={styles.horizontalLine}>
                    <Image src="/svgs/hrline.svg" alt="HrLine" />
                  </div>
                  <button className={styles.fullWidthButton1}>Sign Up</button>
                </>
              ) : (
                <>
                  {isLoading ?(
                  <div className={styles.progressbar}>
                  </div>):(
                    <div className={styles.horizontalLine}>
                    <Image src="/svgs/hrline.svg" alt="HrLine" />
                  </div>
                  )}
                  <button className={styles.fullWidthButton1} 
                  disabled={isDisabled}
                  onClick={()=>{setForgotPassword(false);setErrors({});setErrorMessage('');}}>Login Page</button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.rightContent}>
          <img
            src="/svgs/Mtt-logo.svg"
            className={styles.modalLogo}
            alt="Logo"
          />
        </div>
      </div> */}
      <div className={styles.loginFormBg}>
        <Container fluid>
          <Row className="justify-content-center align-items-center">
            <Col sm={8}>
              <div className={styles.loginFormLeftHeadCont}>
                <div className={styles.loginFormLeftHead}>
                  <div className="pt-5 pb-4 px-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={styles.formTextHead}>
                        <h5 className={styles.formLogin}>
                          {forgotPassword ? "Reset Password" : "Login"}
                        </h5>
                        <span className={styles.formpara}>
                          {forgotPassword ? "Enter Registered Email-Id" : ""}
                        </span>
                      </div>
                      <Image
                        src="/svgs/close.svg"
                        alt="CloseIcon"
                        className={styles.closeButton}
                        width={24}
                        onClick={handleClose}
                      />
                    </div>
                    <div className={styles.formInputHead}>
                      <input
                        type="text"
                        placeholder="Email "
                        autoComplete="nope"
                        className={styles.inputField}
                        value={emailOrMobile}
                        onChange={handleChangeEmailOrMobile}
                      />
                      {errors.emailOrMobile && (
                        <span className={styles.error}>
                          {errors.emailOrMobile}
                        </span>
                      )}
                      {!forgotPassword && (
                        <>
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            autoComplete="new-password"
                            className={styles.inputField}
                            value={password}
                            onChange={handleChangePassword}
                          />
                          {errors.password && (
                            <span className={styles.error}>
                              {errors.password}
                            </span>
                          )}
                          {errors.password
                            ? ""
                            : errorMessage && (
                                <div className={styles.error}>
                                  {errorMessage}
                                </div>
                              )}

                          <div className={styles.showPasswordWrapper}>
                            <label
                              htmlFor="showPassword"
                              className={styles.forgotPassword}
                            >
                              <input
                                type="checkbox"
                                id="showPassword"
                                checked={showPassword}
                                onChange={toggleShowPassword}
                                className={styles.checkbox}
                              />{" "}
                              Show Password
                            </label>

                            {!forgotPassword && (
                              <span
                                className={styles.forgotPassword}
                                onClick={handleForgotPasswordClick}
                              >
                                Forget password?
                              </span>
                            )}
                          </div>
                        </>
                      )}

                      {passwordResetMessage && (
                        <p className={styles.mathematic}>
                          A new password has been sent to your registered email.
                        </p>
                      )}
                      <h6></h6>
                      <button
                        className={styles.fullWidthButton}
                        onClick={
                          forgotPassword ? handleResetPassword : handleLogin
                        }
                        disabled={isDisabled}
                      >
                        {forgotPassword ? "Reset Password" : "Log In"}
                      </button>
                      {!forgotPassword ? (
                        <>
                          {/* <div className={styles.horizontalLine}>
                          <Image src="/svgs/hrline.svg" alt="HrLine" />
                        </div>
                        <button className={styles.fullWidthButton1}>
                          Sign Up
                        </button> */}
                        </>
                      ) : (
                        <>
                          {isLoading ? (
                            <div className={styles.progressbar}></div>
                          ) : (
                            <div className={styles.horizontalLine}>
                              <Image src="/svgs/hrline.svg" alt="HrLine" />
                            </div>
                          )}
                          <button
                            className={styles.fullWidthButton1}
                            disabled={isDisabled}
                            onClick={() => {
                              setForgotPassword(false);
                              setErrors({});
                              setErrorMessage("");
                            }}
                          >
                            Login Page
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <Image
                src="/pngs/My-Talking-Tree_Logo.png"
                className={styles.modalLogo}
                alt="Logo"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default LoginForm;
