import { getEnvUrl,getToken,axiosInstance } from "../utils/Api";

  export const getContentDetails = async (content_id) =>{ 
    try {
        const response = await axiosInstance.get(`content/getContentData/${content_id}`,{
            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  
  export const getContentSource = async (content_id) =>{ 
    try {
        const response = await axiosInstance.get(`content/getContentSource/${content_id}`,{
            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  
  export const getContentRelatedFcQuiz = async (content_id) =>{ 
    try {
        const response = await axiosInstance.get(`content/getFcQuizByContentId/${content_id}`,{
            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  


  export const getDetailViewData = async (grade_id) =>{ 
    try {
        const response = await axiosInstance.get(`content/detailViewData?grade_id=${grade_id}`,{
            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  

  export const searchContents = async (name) =>{ 
    try {
        const response = await axiosInstance.get(`content/searchContent?name=${name}`,{

            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  
  export const getQuizFc = async (grade_id) =>{ 
    try {
        const response = await axiosInstance.get(`content/getFcQuizByGrade?grade_id=${grade_id}`,{

            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  
  export const getAllQuizFc = async (grade_id,contentType) =>{ 
    try {
        const response = await axiosInstance.get(`content/getAllFcQuizByGrade?grade_id=${grade_id}&contentType=${contentType}`,{

            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  
  export const getQuizVideosBySubject= async (grade_id) =>{ 
    try {
        const response = await axiosInstance.get(`content/getFcQuizBySubjects?grade_id=${grade_id}`,{

            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        }); 
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  
  export const getQuizVideosFcBySubjectId= async (grade_id,concept_id) =>{ 
    try {
        const response = await axiosInstance.get(`content/getFcQuizBySubjectId?grade_id=${grade_id}&concept_id=${concept_id}`,{

            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        }); 
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  
  export const getContentIndex= async (grade_id) =>{ 
    try {
        const response = await axiosInstance.get(`content/getContentIndex?grade_id=${grade_id}`,{
            headers: { 
                Authorization: `Bearer ${getToken()}`,
            }
        });
        return response.data;
      } catch (error) {
        throw error;
      } 
  }  