import React, { useState } from "react";
import classes from "../../styles/HomePage/homepage.module.css";
import { colors } from "../../utils/dummyData";
import { Image } from "react-bootstrap";
import Lottie from "react-lottie";
import playAudioAnimation from "../../assets/json/audioPlayJson.json";
import { useNavigate } from "react-router-dom";
const CarouselCard = ({ index, item, averageColor, headingTitle }) => {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: playAudioAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const getBackgroundColor = (index) => {
    return colors[index % colors.length];
  };
  const navigatePage = (item) => { 
    if (item.item_type == "VIDEO") {
      navigate(`/videoDetailView/${item.id}`);
    } else if (item.item_type == "QUIZ") {
      navigate(`/quizDetailView/${item.id}`);
    } else if (item.item_type == "FLASH_CARD") {
      navigate(`/flashCardDetailView/${item.id}`);
    } else if (item.item_type == "GRADE") {
      navigate(`/content-list/${item.id}`);
    } else if (item.item_type == "SUBJECT") {
      navigate(`/subjects/${item.id}`);
    } else if (item.item_type == "Screen free learn") {
      navigate(`/videoDetailView/${item.id}`);
    }
  }; 
  const titleColor = (title) => {
    const colorClasses = {
      "Flash Cards": "rgba(255, 129, 37, 1)",
      Spellings: "rgba(255, 37, 129, 1)",
      Test: "rgba(68, 37, 255, 1)",
      Quiz: "rgba(255, 63, 37, 1)",
      Video: "rgba(17, 140, 255, 1)",
    };
    return colorClasses[title] || "default-color";
  };
  return (
    <div
      className={classes.card}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hovered &&
        (item.name ||
          (item.title !== "Subject" && item.title !== "Popular Subjects" && headingTitle !== "Popular Subjects") ||
          headingTitle === "Screen free learn") && (
          <div
            className={classes.overlay}
            style={{
              backgroundColor:
                headingTitle === "Screen free learn" ? "black" : averageColor,
            }}
          ></div>
        )}
      <div
        className={`${
          item?.name
            ? classes.imageContainer
            : headingTitle === "Grades"
            ? classes.imageContainer3
            : classes.imageContainer2
        }`}
      >
        <div
          className={`${
            headingTitle === "Screen free learn" && classes.ImgDiv
          }`}
          style={{ minHeight: "100px" }}
        >
          <img
            onClick={() => {
              navigatePage(item);
            }}
            src={
              !hovered &&
              (headingTitle === "Subjects" ||
                headingTitle === "Popular Subjects")
                ? "/pngs/Subject-Hove-Img.png"
                : hovered &&
                  (headingTitle === "Subjects" ||
                    headingTitle === "Popular Subjects")
                ? item.img
                : item.img
            }
            alt="Img"
            role="button"
            className={`${classes.image} ${
              headingTitle === "Grades" && classes.gradeImage
            } ${
              hovered &&
              headingTitle === "Screen free learn" &&
              classes.hoverAnimateOpacity
            }`}
          />
         {item.subject_name && !hovered && <div className={classes.label}>{item.subject_name}</div>}
        </div>
        {hovered && headingTitle === "Screen free learn" && (
          <div className={classes.hoverAnimateImg}>
            <Lottie options={defaultOptions} />
          </div>
        )}
        <div className={classes.playHead}>
          {item.name && item.title && headingTitle !== "Screen free learn" && (
            <img
              src={"/svgs/play_circle.svg"}
              alt="play"
              className={classes.playIcon}
            />
          )}
          {headingTitle === "Screen free learn" && (
            <div className={classes.audioWaveHead}></div>
          )}
          {hovered &&
            (headingTitle === "My List or Favourites" ||
              headingTitle === "You May also like" ||
              headingTitle === "Fun Facts" ||
              headingTitle === "Learning Aids") && (
              <div className={classes.hoverPlay}></div>
            )}
          {item.title !== "Subject" &&
            item.title !== "Popular Subjects" &&
            headingTitle !== "Screen free learn" &&
            headingTitle !== "Learning Aids" &&
            headingTitle !== "Popular Subjects" &&
            headingTitle !== "Grades" && item?.title && (
              <div
                className={classes.curveHead}
                // style={{ borderTop: `29px solid ${getBackgroundColor(index)}` }}
                style={{ borderTop: `29px solid ${titleColor(item?.title)}` }}
              >
                <span className={classes.gameTitle}>{item?.title}</span>
              </div>
            )}
        </div>

        <div className={classes.nameHead}>
          {item?.name && (
            <div className={classes.content}>{item?.name || "NA"}</div>
          )}
          {headingTitle === "Screen free learn" && (
            <Image src="/svgs/speaker-filled.svg" />
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
