import "./skeleton.css"

const GradeSkeleton = () => {
    return (
      <>
      <div className="skeleton-line"></div>
       <div className="row mb-4 mx-5">
        <div className="col-3 pd-5"><div className="skeleton-header"></div></div>
        <div className="col-3 pd-5"><div className="skeleton-header"></div></div> 
        <div className="col-3 pd-5"><div className="skeleton-header"></div></div> 
        <div className="col-3 pd-5"><div className="skeleton-header"></div></div>  
        </div>
    </>
     
    )

}
export default GradeSkeleton;