import React,{useEffect,useRef} from "react";
import Lottie from "react-lottie";
import { Col, Container, Image, Row } from "react-bootstrap";
import classes from "../../../styles/quizLevels.module.css";
import quizCongraxSmileAnimation from "../../../assets/json/quizSmile.json";
import quizCongraxAnimation from "../../../assets/json/quizCongrax.json";
import { useNavigate } from "react-router-dom";
const lottieOptions = (animationData) => ({
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
});

const QuizCongratulation = ({totalQues,caCount,quiz_id,tryAgain,precentage}) => {
  const navigate = useNavigate();
  const audioRef = useRef(null);
useEffect(() => {  
  audioRef.current = new Audio(`/sounds/congratulations.wav`);
  setTimeout(() => {
    audioRef.current.play().catch(error => {
      console.error('Error playing audio:', error);
  });
  }, 500);  
  return () => {
      if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
      }
  };
}, []); 

  return (
    <div className={classes.quizCongraxBg}>
      <Container fluid className={classes.quizCongraxContainer}>
        <Row>
          <Col>
            <div className={classes.quizCongraxRowHead}>
              <div className={classes.quizCongraxRowHead2}>
                {/* <Image src="/svgs/arrow-back-level.svg"  role="button"/> */}
                <Image src="/svgs/Quiz/quiz-congrax-close-bg-icon.svg"  role="button" onClick={()=>{navigate(`/quizDetailView/${quiz_id}`)}}/>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={classes.quizCongraxRowHead3}>
            <div className={classes.quizCongraxTitle}>
              <span className={classes.partyImg}>
                <Image src="/svgs/Quiz/Partying-face.svg" />
              </span>
              Congratulations
              <div className={classes.quizCongarxSmileAnimation}>
                <Lottie
                  options={lottieOptions(quizCongraxSmileAnimation)}
                  width={56}
                />
              </div>
            </div>
            <div className={classes.quizCongraxTitleOne}>
              You scored {precentage}%
              <div className={classes.quizCongraxAnimation}>
                <Lottie
                  options={lottieOptions(quizCongraxAnimation)}
                  width={500}
                />
              </div>
            </div>
            <span className={classes.quizCongraxPara}>
            You have answered {caCount}/{totalQues} questions correctly in the quiz.
              {/* You had answered {caCount} Quiz. You can move to the next Level Quiz */}
            </span>
            <button  onClick={()=>{navigate(`/quizDetailView/${quiz_id}`)}} type="button" className={classes.quizCongraxButton}>
              {/* Level 2 Quiz */}
              Done
              {/* <Image src="/svgs/Quiz/arrow_right.svg" /> */}
            </button>
            <span className={classes.quizCongraxParaOne} onClick={tryAgain}>
              If you want to try again this level
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default QuizCongratulation;
