import React, { useState,useEffect,useRef } from "react";
import classes from "../../../styles/quizLevels.module.css";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { getEnvUrl } from "../../../utils/Api";
import { useNavigate } from "react-router-dom";
import { getMediaUrl } from "../../../utils/Api";
const Tempalte1 = (
  {
    fc_id,
    currentSNumber,
    totalSlides,
    currentSlide,nextSlide,prevSlide
  }) => {
    const audioRef = useRef(null);
    const ClickAudioRef = useRef(null);
    ClickAudioRef.current = new Audio(`/sounds/ACTIONS_Click-1.mp3`);
    const clickSound=()=>{ 
      ClickAudioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
    const navigate = useNavigate();
    const quitFc = () => {
      if (window.confirm(`Are you sure you want to quit the flash card?`)) {
        navigate(`/flashCardDetailView/${fc_id}`);
      }
    };
    useEffect(() => { 
      audioRef.current = new Audio(`${getMediaUrl()}/${currentSlide.audio_key}`);
      setTimeout(() => {
        audioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
      }, 500);

      const handleAudioEnded = () => {
        setTimeout(() => {
          if(currentSNumber!=totalSlides){
            nextSlide()
          }
          
        }, 1000); 
      }; 
      audioRef.current.addEventListener('ended', handleAudioEnded);

      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
        }
      };
    }, [currentSlide.id]);
  return (
    <div className={classes.cardContainerHead}>
      <Container fluid className={`${classes.cardContainerSubHead} `}>
        <Row>
          <Col sm={12}>
            <div className={classes.cardRowHead}>
              <div className={classes.cardRowHead2}>
                <div className={classes.cardSubHead} >
                  {/* <Image src="/svgs/arrow-back-level.svg" /> */}
                  {/* <span className={classes.cardName}>Flash Cards</span> */}
                  <span className={classes.cardName}>Flash Cards {currentSNumber}/{totalSlides}</span> 

                   
                </div>
                <div className={classes.logo} onClick={quitFc}>
                  <Image src="/pngs/Logo.png" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center"> 
        <Col sm={1}>
        {currentSNumber!=1 &&  
            <div className={classes.arrowButtonA} onClick={()=>{clickSound();prevSlide();} }>
              <img src="/svgs/arrowLeft.svg" alt="" />
            </div>
          }
          </Col>
         
          <Col sm={7} className={classes.cardRowImgHead1}>
            <div className="d-flex justify-content-center align-items-center">
              <div className={classes.imgCard}>
                <div className={classes.imgCardA}>
                <div className={classes.slideImage}>
                  <img src={`${getEnvUrl()}/fc/slide/image/${currentSlide.id}?v=${Math.floor(Date.now() / 1000)}`} alt="" />
                  </div>
                  <div className={classes.imgCardTag}>
                    <span>{currentSlide.title}</span>
                  </div>
                </div>
              </div>
              <div className={classes.imgCardContent}>
                <h3>{currentSlide.title}</h3>
                <div className={classes.contentBottomLine}></div>
                <span>
                  {currentSlide.slide_text}
                </span>
              </div>
            </div>
          </Col>
          <Col sm={1}>
          {currentSNumber!=totalSlides &&  
            <div className={classes.arrowButtonA}  onClick={()=>{clickSound();nextSlide();}}>
              <img src="/svgs/arrowRight.svg" alt="" />
            </div>
          }
          </Col>
         
        </Row>
      </Container>
    </div>
  );
};

export default Tempalte1;
