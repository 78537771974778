import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Nav, Image } from "react-bootstrap";
import Styles from "../styles/HomePage/homepage.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { logout } from "../services/userService";
import { useSelector, useDispatch } from 'react-redux';
import { getAllSubjects } from "../services/gradeService";
import { getEnvUrl, getToken } from "../utils/Api";
import { fetchProfilePicture, FetchUserData } from "../redux/actions/homePageActions";
const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const pathSegments = currentPath.split("/")[1];
  const userLoginCheck = localStorage.getItem("authenticated");
  const userTokenCheck = localStorage.getItem("token");
  const [activeTab, setActiveTab] = useState(pathSegments || "home");
  const [isSearch, setIsSearch] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const image = useSelector(state => state.Home.profilePicture.url);
  const userProfileData = useSelector(state => state.Home.UserData);

  const hanldeLogout = async () => {
      if (window.confirm('Are you sure you want to logout?')) {
         logout().then(() => {
          localStorage.clear();
          navigate("/login");
         });
      }
  };
  useEffect(() => {
    getAllSubjects().then((res) => {
      if (res.status) {
        setSubjects(res.data);
      }
    });
  }, []);
  const handleSearchClick = () => {
    if (userLoginCheck) {
      setIsSearch(true);
    }
    navigate("/search");
  };
  const expand = "md";
    useEffect(() => {
      dispatch(fetchProfilePicture());
      dispatch(FetchUserData());
  }, [dispatch]);
  const profileImg =
    getToken() === "undefined"
      ? "/svgs/avathar.svg"
      : `${getEnvUrl()}/user/getProfilePic/${getToken()}?width=100`;
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <Navbar expand={expand} fixed="top" className={`mb-3 container-fluid p-0`}>
        <Container fluid className={`${Styles.HeaderMain} ${Styles.MainNav}`}>
          <Navbar.Brand
            onClick={() => {
              navigate("/");
              handleTabClick("home");
            }}
            className={`${Styles.logoClick} ${
              !userTokenCheck ? Styles.onClickDisabled : ""
            }
            `}
          >
            <Image src="/pngs/Logo.png" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Offcanvas
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className={Styles.BodyHead}>
              <Nav className={`${Styles.LeftSideHead} leftNav`}>
                <Nav.Link
                  onClick={() => {
                    navigate("/");
                    handleTabClick("home");
                  }}
                  className={`${activeTab === "home" ? Styles.active : ""}  ${
                    !userTokenCheck ? Styles.onClickDisabled : ""
                  }`}
                  aria-current="page"
                >
                  Home
                </Nav.Link>
                <NavDropdown
                  title="Subjects"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                  renderMenuOnMount={true}
                  onClick={() => {
                    handleTabClick("subjects");
                    navigate("/subjects");
                  }}
                  className={`${
                    activeTab === "subjects" ? Styles.active : ""
                  }  ${!userTokenCheck ? Styles.onClickDisabled : ""}`}
                >
                  {subjects.map((subject) => (
                    <NavDropdown.Item
                      href={`#${subject.concept_id}s`}
                      style={{
                        textAlign: 'center',
                        whiteSpace: 'normal', 
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      key={subject.concept_id}
                    >
                      {subject.concept_name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <NavDropdown
                  title="Smart Learn"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                  renderMenuOnMount={true}
                  onClick={() => {
                    navigate("/smart-learn");
                    handleTabClick("smart-learn");
                  }}
                  className={`${
                    activeTab === "smart-learn" ? Styles.active : ""
                  } ${!userTokenCheck ? Styles.onClickDisabled : ""}`}
                >
                  <NavDropdown.Item href="#flash-cards" className="text-center">
                    Flash Cards
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href="#spellings" className="text-center">
                    Spellings
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#test" className="text-center">
                    Test
                  </NavDropdown.Item> */}
                  <NavDropdown.Item href="#quiz" className="text-center">
                    Quiz
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href="#"
                  onClick={() => {
                    navigate("/ai");
                    handleTabClick("ai");
                  }}
                  className={`${activeTab === "ai" ? Styles.active : ""} ${
                    !userTokenCheck ? Styles.onClickDisabled : ""
                  }`}
                >
                  Artificial Intelligence
                </Nav.Link>
              </Nav>
              <div
                className={`${Styles.RightSideHead} ${
                  !userTokenCheck ? Styles.onClickDisabled : ""
                }`}
              >
                <Image
                  src="/svgs/search.svg"
                  width="15"
                  height="15"
                  className={Styles.SearchIcon}
                  onClick={handleSearchClick}
                />
                {/* <div className={Styles.AvatharHead}> */}
                <Nav>
                  <NavDropdown
                    title={
                      <Image
                        src={image}
                        width="35"
                        height="35"
                        alt="profile"
                        roundedCircle
                      />
                    }
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                    align="end"
                    renderMenuOnMount={true}
                    className={`${Styles.RightSideHeadDrop} ${Styles.profileMenuCard} ${
                      !userTokenCheck ? Styles.onClickDisabled : ""
                    }`}
                  >
                    <div className={Styles.CustomDropdown}>
                      <div className="row m-0">
                        <h5
                          className="text-white"
                          style={{ textAlign: "center" }}
                        >
                          {userProfileData?.data?.user_name}
                        </h5>
                        <NavDropdown.Item
                          onClick={() => {
                            navigate("/profile");
                          }}
                          className={Styles.CustomDropdownItem}
                        >
                          Manage profile
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={hanldeLogout}
                          className={Styles.CustomDropdownItem}
                        >
                          Log Out
                        </NavDropdown.Item>
                      </div>
                    </div>
                  </NavDropdown>
                </Nav>
                {/* </div> */}
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {/* {isSearch && <Search />} */}
    </>
  );
};

export default Header;
